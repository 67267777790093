import Axios from "./index";

const SingIN = async (payload) => {
  try {
    const res = await Axios.post("/auth/login", payload);
    const data = res && res.data;
    return { data };
  } catch (err) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};
const deleteBlogByID = async (id) => {
  try {
    const response = await Axios.delete(`/blogs/${id}`);
    return response?.data;
  } catch (error) {
    console.error("GET error:", error);
    throw error?.response?.data?.message; // Throw th{e error to be caught by the caller
  }
};

const uploadBlog = async (payload) => {
  try {
    const response = await Axios.post(`/blogs`, payload);
    return response;
  } catch (error) {
    console.error("GET error:", error);
    throw error?.response?.data?.message; // Throw th{e error to be caught by the caller
  }
};
const updateBlog = async (payload, id) => {
  try {
    const response = await Axios.put(`/blogs/${id}`, payload);
    return response;
  } catch (error) {
    console.error("GET error:", error);
    throw error?.response?.data?.message; // Throw th{e error to be caught by the caller
  }
};

const getBlogDetails = async (id) => {
  try {
    const response = await Axios.get(`/blogs/${id}`);
    return response?.data;
  } catch (error) {
    console.error("GET error:", error);
    throw error?.response?.data?.message; // Throw th{e error to be caught by the caller
  }
};

export { SingIN, uploadBlog, deleteBlogByID, updateBlog ,getBlogDetails};
