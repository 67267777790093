import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import PSite01 from "../../assets/img/LifeAyanaSliderImages/PSite01.jpg";
import PSite02 from "../../assets/img/LifeAyanaSliderImages/PSite02.jpg";
import PSite03 from "../../assets/img/LifeAyanaSliderImages/PSite03.jpg";
import PSite04 from "../../assets/img/LifeAyanaSliderImages/PSite04.jpg";
import PSite05 from "../../assets/img/LifeAyanaSliderImages/Frame4.png";
import PSite06 from "../../assets/img/LifeAyanaSliderImages/Frame5.png";

const responsive = {
  xldesktop: {
    breakpoint: { max: 2526, min: 1920 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 1536, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const AyanaLifeSlider = () => {
  return (
    <section className="p-0">
      <div className="">
        <Carousel
          autoPlay={true}
          infinite
          responsive={responsive}
          itemClass="p-2"
        >
          <img className="w-100" src={PSite01} alt="arrow" />
          <img className="w-100" src={PSite02} alt="arrow" />
          <img className="w-100" src={PSite03} alt="arrow" />
          <img className="w-100" src={PSite04} alt="arrow" />
          <img className="w-100" src={PSite05} alt="arrow" />
          <img className="w-100" src={PSite06} alt="arrow" />
        </Carousel>
      </div>
    </section>
  );
};

export default AyanaLifeSlider;
