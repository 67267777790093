import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import Careers from "./routes/careers/careers";
import Offerings from "./routes/offerings/offerings";
import Media from "./routes/media/media";
import Sustainability from "./routes/sustainability/sustainability";
import CsrInitiative from "./routes/csr-initiative/csr-initiative";
import QualitySafety from "./routes/quality-safety/quality-safety";
import AyanaLife from "./routes/ayana-life/ayana-life";
import Governance from "./routes/governance/governance";
import Partners from "./routes/partners/partners";

import "./index.css";
import App from "./App";

import Contact from "./routes/contact/contact";
import Suppliers from "./routes/suppliers/suppliers";
// import JobForm from "./components/JobForm/JobForm";
import AssetsComponent from "./routes/assests-component/assests-component";
import VendorForm from "./routes/venderform/vendor-form";
import ScrollButton from "./components/ScrollButton/ScrollButton";
import Introduction from "./routes/introduction.jsx/introduction";
import Blog from "./routes/blogs/blogs";
import BlogDetail from "./routes/blog-detail/blog-detail";
import LeaderShip from "./routes/leadership/leadership";
import JobDescription from "./components/JobDescription/JobDescription";
import Directors from "./routes/director-board/director-board";
import ApplyNow from "./routes/apply-now/apply-now";
import ThankYou from "./routes/thankyou/thankyou";
import SiteMap from "./routes/sitemap/sitemap";
import { ClearBrowserCache } from "react-clear-browser-cache";
import TermsAndConditions from "./routes/terms-and-conditions/terms-and-conditions";
import PrivacyPolicy from "./routes/privacy-policy/privacy-policy";
import AdminLayout from "./routes/admin";
import Login from "./routes/admin/login";
import Enquiry from "./routes/admin/enquiry";
import AdminMedia from "./routes/admin/media";
import Dashboard from "./routes/admin/dashboard";
import BlogsTable from "./routes/admin/blogs";
import BlogsCreate from "./routes/admin/blogs/blogsCreate";
import BlogsEdit from "./routes/admin/blogs/blogsEdit";
import ScrollToTop from "./components/ScrollToTop";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ClearBrowserCache>{(contextValue) => null}</ClearBrowserCache>;
    {/* <ScrollButton /> */}
    <ScrollToTop />
    <Routes>
      <Route path="/"   element={<App />} />
      <Route path="/about" element={<Introduction />} />
      <Route path="/careers" element={<Careers />} />
      <Route path="/offerings" element={<Offerings />} />
      <Route path="/ayana-life" element={<AyanaLife />} />
      <Route path="/media" element={<Media />} />
      <Route path="/sustainability" element={<Sustainability />} />
      <Route path="/csr-initiative" element={<CsrInitiative />} />
      <Route path="/quality-safety" element={<QualitySafety />} />
      <Route path="/governance" element={<Governance />} />
      <Route path="/partners" element={<Partners />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/suppliers" element={<Suppliers />} />
      {/* <Route path="/jobform" element={<JobForm />} /> */}
      <Route path="/assets" element={<AssetsComponent />} />
      <Route path="/vendor-form" element={<VendorForm />} />
      <Route path="/job-details/:slug" element={<JobDescription />} />
      <Route path="/director-board/:id" element={<Directors />} />
      <Route path="/blogs" element={<Blog />} />
      <Route path="/blog-detail/:id" element={<BlogDetail />} />
      <Route path="/leadership/:id" element={<LeaderShip />} />
      <Route path="/apply-now/:id" element={<ApplyNow />} />
      <Route path="/thankyou" element={<ThankYou  />} />
      <Route path="/sitemap" element={<SiteMap />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />

      <Route path="/admin" element={<AdminLayout />}>
        <Route index path="dashboard" element={<Dashboard />} />
        <Route path="enquiry" element={<Enquiry />} />
        <Route path="media" element={<AdminMedia />} />
        <Route path="blogs" element={<BlogsTable />} />
        <Route path="blogs/create" element={<BlogsCreate />} />
        <Route path="blogs/edit/:id" element={<BlogsEdit />} />
      </Route>

      <Route path="/login" element={<Login />} />
    </Routes>
  </BrowserRouter>
);
reportWebVitals();

serviceWorkerRegistration.register();
