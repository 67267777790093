import { NewTimeLine } from "./NewTimeLine";
import timelineData from "./timelineData";
import './timeline-container.css'

export const Timeline = () =>
  timelineData.length > 0 && (
    <div className="timeline-container">
      {timelineData.map((data, idx) => (
        <NewTimeLine data={data} key={idx} />
      ))}
    </div>
  );
