import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import "./contact-form.scss";
import PreloaderForm from "../components/PreloaderForm/PreloaderForm";

import config from "../config";

const BASE_URL = config.base_url;



const ContactForm = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [emailError, setEmailError] = useState("");
  const validateEmail = (e) => {
    var email = e.target.value;
    if (validator.isEmail(email)) {
      setEmailError("");
    } else {
      setEmailError("Enter valid Email!");
    }
  };

  const navigate = useNavigate();
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [company_name, setCompanyName] = useState("");
  const [company_address, setCompanyAddress] = useState("");
  const [query_type, setQueryTpe] = useState("");
  const [query_description, setQueryDescription] = useState("");

  async function registerUser(event) {
    event.preventDefault();
    setIsLoading(true);
    const response = await fetch(
      `${BASE_URL}api/save-contact`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          first_name,
          last_name,
          email,
          phone_number,
          company_name,
          company_address,
          query_type,
          query_description,
        }),
      }
    );

    const data = await response.json();
    console.log(response);

    if (data.status === 201) {
      navigate("/thankyou?s=contact");
    }
  }

  return (
    <div className="col-md-6 formContainer">
      <h4>Enquiry</h4>
      <p>Fill in the following details and we will get back to you shortly</p>
      <form className="contact_form" onSubmit={registerUser}>
        <div className="row ">
          <div className="col-md-6 mt-3">
            <input
              value={first_name}
              onChange={(e) => setFirstName(e.target.value)}
              type="text"
              id="firstname"
              name="firstname"
              placeholder="First Name*"
              className="form-control inputForm"
              required={true}
            ></input>
          </div>
          <div className="col-md-6 mt-3">
            <input
              value={last_name}
              onChange={(e) => setLastName(e.target.value)}
              type="text"
              id="lastname"
              name="lastname"
              placeholder="Last Name*"
              className="form-control inputForm"
              required={true}
            ></input>
          </div>
          <div className="col-md-6 mt-3">
            <input
              value={company_name}
              onChange={(e) => setCompanyName(e.target.value)}
              type="text"
              id="businessname"
              name="businessname"
              placeholder="Business Name/ Company Name"
              className="form-control inputForm"
              required={true}
            ></input>
          </div>
          <div className="col-md-6 mt-3">
            <input
              value={phone_number}
              onChange={(e) => setPhoneNumber(e.target.value)}
              type="tel"
              id="phoneno"
              name="phoneno"
              placeholder="Phone No. *"
              className="form-control inputForm"
              required={true}
              maxlength="10"
              pattern="[1-9]{1}[0-9]{9}"
              title="Number should not be less than 10 or more"
            ></input>
          </div>
          <div className="col-md-6 mt-3">
            <input
              value={email}
              type="email"
              placeholder="Email Address *"
              id="emailaddress"
              name="emailaddress"
              className="form-control inputForm"
              onChange={(e) => {
                validateEmail(e);
                setEmail(e.target.value);
              }}
              // pattern="/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i"
            />
            <span style={{ color: "red" }}>{emailError}</span>
          </div>
          <div className="col-md-6 mt-3">
            <input
              value={company_address}
              onChange={(e) => setCompanyAddress(e.target.value)}
              type="text"
              id="address"
              name="address"
              placeholder="Address / Company Address"
              className="form-control inputForm"
              required={true}
            ></input>
          </div>
          <div className="col-md-12 mt-3">
            <select
              value={query_type}
              onChange={(e) => setQueryTpe(e.target.value)}
              className="form-control inputForm select_dropdown"
              style={{
                // background: "#F2F2F2",
                // boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "4px",
                width: "100%",
              }}
            >
              <option disabled value="">
                Query Type
              </option>
              <option value="buisness_development">Business Development</option>
              <option value="human_resource">Human Resource</option>
              <option value="partnership">Partnership</option>
              <option value="supplier">Supplier</option>
              <option value="media">Media</option>
              {/* <option value="grievance">Grievance</option> */}
              <option value="general_enquiries">General Enquiries</option>
              <option value="others">Others</option>
            </select>
          </div>
          <div className="col-md-12 mt-3">
            <textarea
              value={query_description}
              onChange={(e) => setQueryDescription(e.target.value)}
              style={{
                // background: "#F2F2F2",
                // boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "4px",
              }}
              name="comment"
              id="comment"
              className="form-control "
              placeholder="Query Description"
              rows="3"
            />
          </div>
          <div className="col-md-12 mt-3 preloaderbtnContact">
            {isLoading && (
              <div className="preloaderbtnSpinContact mt-1">
                <PreloaderForm />
              </div>
            )}
            <input
              type="submit"
              value="submit"
              className="submitButtonContact "
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
