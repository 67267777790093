import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import Awards01 from "../../assets/img/recognition_slider_imgs/1.jpg";
import Awards02 from "../../assets/img/recognition_slider_imgs/2.jpg";
import Awards03 from "../../assets/img/recognition_slider_imgs/3.jpg";
import Awards05 from "../../assets/img/recognition_slider_imgs/5.jpg";
import Awards06 from "../../assets/img/recognition_slider_imgs/6.jpg";
import Awards07 from "../../assets/img/recognition_slider_imgs/7.jpg";
// import Awards08 from "../../assets/img/recognition_slider_imgs/8.jpg";
// import Awards09 from "../../assets/img/recognition_slider_imgs/9.jpg";
// import Awards10 from "../../assets/img/recognition_slider_imgs/10.jpg";
// import Awards13 from "../../assets/img/recognition_slider_imgs/a13.jpg";

const responsive = {
  xldesktop: {
    breakpoint: { max: 2526, min: 1920 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1536, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const AyanaRewardSlider = () => {
  return (
    <section className="">
      <div className="">
        <Carousel
          autoPlay={true}
          infinite
          responsive={responsive}
          itemClass="p-2"
        >
          <img className="w-100" src={Awards01} alt="arrow" />
          <img className="w-100" src={Awards02} alt="arrow" />
          <img className="w-100" src={Awards03} alt="arrow" />
          <img className="w-100" src={Awards05} alt="arrow" />
          <img className="w-100" src={Awards06} alt="arrow" />
          <img className="w-100" src={Awards07} alt="arrow" />
          {/* <img className="w-100" src={Awards08} alt="arrow" />
          <img className="w-100" src={Awards09} alt="arrow" />
          <img className="w-100" src={Awards10} alt="arrow" /> */}
        </Carousel>
      </div>
    </section>
  );
};

export default AyanaRewardSlider;
