import React from 'react'
import Footer from '../../components/Footer/Footer'
import './thankyou.scss'
import Navbar from '../../components/Navbar/Navbar'
import { useSearchParams } from 'react-router-dom'

const ThankYou = () => {

    const [query, setQuery] = useSearchParams();

    const sub = query.get('s');

  
let message;
switch(sub){ 
    case "contact":  {
        message = "Thank you for contacting us. We will get back to you soon";
        break;
    }
    case "career" : {
        message = "Thank You for applying. We will get back to you!";
        break;
    } 
    case "vendor" : {
        message = "Thank you for showing your interest. We will get back to you soon.";
        break;
    } 
  
}


    return (
        <div>
            <Navbar />
            <div className='thankyou'>
                <h3> { message } </h3>
            </div>
            <Footer />
        </div>
    )
}

export default ThankYou
