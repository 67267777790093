import React from "react";
import "./offerings.scss";
import OfferingsTab from "../../components/OfferingsTab/OfferingsTab";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import offering_banner from "../../assets/img/offerings-banner.jpg";

const Offerings = () => {
  return (
    <div>
      <Navbar />
      <section
        className="pt-0 pb-0 position-relative banner_image_overlay"
        id={"offerings"}
      >
        <img src={offering_banner} alt="" className="img-fluid w-100" />
        <div className="breadcrumb_text">
          <h4 className="breadcrumb_title">Offerings & Projects</h4>
          <p className="breadcrumb_link">
            <Link to="/"> Home</Link> | Offerings & Projects
          </p>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row introductionContainer">
            <div className="col-md-12">
              <div>
                <div className="row introductionContainer">
                  <div className="col-md-9">
                    <div
                      className="title pt-4"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <h4></h4>
                      <br />
                    </div>
                  </div>
                </div>
                <p>
                  We are focusing on creating green and clean energy for a
                  sustainable future. In recent times many investors and
                  governments of developing and developed nations are
                  emphasising on clean energy to cut down on carbon emissions.
                  The Indian renewable energy market is rapidly growing, ranked
                  5th globally in solar power deployment. The nation’s
                  government has urged the energy sector to generate 50 percent
                  of its energy from renewable sources by 2030.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="offerings_tab_section bg_grey" id={"offer"}>
        <OfferingsTab />
      </section>

      <Footer />
    </div>
  );
};

export default Offerings;
