import React from "react";
import { Link } from "react-router-dom";

import Navbar from "../../components/Navbar/Navbar";
import termsconditionsbanner from "../../assets/img/TermsConditons/terms_condition.png";
import icon1 from "../../assets/img/TermsConditons/1.png";
import icon2 from "../../assets/img/TermsConditons/2.png";
import icon3 from "../../assets/img/TermsConditons/3.png";
import icon4 from "../../assets/img/TermsConditons/4.png";
import icon5 from "../../assets/img/TermsConditons/5.png";
import icon6 from "../../assets/img/TermsConditons/6.png";
import icon7 from "../../assets/img/TermsConditons/7.png";
import icon8 from "../../assets/img/TermsConditons/8.png";
import icon9 from "../../assets/img/TermsConditons/9.png";
import icon10 from "../../assets/img/TermsConditons/10.png";
import icon11 from "../../assets/img/TermsConditons/11.png";
import icon12 from "../../assets/img/TermsConditons/12.png";

import arrow from "../../assets/img/TermsConditons/arrow.svg";
import "./terms-condition.scss";
import Footer from "../../components/Footer/Footer";

const TermsAndConditions = () => {
  return (
    <div className="termsandconditions">
      <Navbar />
      <section
        className="pt-0 pb-0 position-relative banner_image_overlay"
        id={"termsandconditions"}
      >
        <img src={termsconditionsbanner} alt="" className="img-fluid w-100" />
        <div className="breadcrumb_text">
          <h4 className="breadcrumb_title">Terms and conditions</h4>
          <p className="breadcrumb_link">
            <Link to="/"> Home</Link> | Terms and conditions
          </p>
        </div>
      </section>

      <section>
        <div className="container termsHeading">
          <h2 className="mb-3">Terms and conditions</h2>
          <p className="mb-3">
            Welcome to the www.ayanapower.com website (hereinafter to as the
            “Website”). This Website is owned and operated by Ayana Renewable
            Power Private Limited, a company registered under the Companies Act,
            1956, having its registered office at Bangalore, India (hereinafter
            referred to as “Ayana”, “we”, “us”). Ayana is involved in the
            business of developing, owning and operating solar, wind and rooftop
            solar projects in South Asian Countries.
          </p>
          <p className="mb-3">
            Any person accessing or visiting the Website for whatever purposes,
            shall be deemed to have accepted all of the terms, conditions and
            notices contained in these Terms and Conditions, along with its
            Information Protection Policy or any amendments/ addendums etc.
            (herein, collectively referred to as “Terms of Use”) made from time
            to time by Ayana at its sole discretion and posted on the Website
            (hereinafter referred to as “User”, “You”, “Your”).
          </p>
          <p className="mb-3">
            This document is to be treated as an electronic record, within the
            meaning accorded to it under the Information Technology Act, 2000
            (hereinafter referred to as the “IT Act”) and the rules made
            thereunder as applicable, which may be amended from time to time.
            This document is published pursuant to the compliance requirements
            under Rule 3 (1) of the Information Technology (Intermediaries
            Guidelines) Rules, 2011 (hereinafter referred to as the “IT
            Intermediaries Guidelines”) which require publishing the rules and
            regulations, Information Protection Policy and user agreement for
            access or usage of the Website.
          </p>
          <p className="mb-3">
            Please read these Terms of Use carefully. Your access to Website
            and/or use of the service constitutes your acceptance of all the
            provisions of these Terms of Use. If you are unwilling to be bound
            by these Terms of Use, do not access the Website and/or use such
            service.
          </p>
        </div>
        <div className="container">
          <div className="col-md-12">
            <div className=" row mt-2 g-3 py-4 justify-content-center">
              <div className=" ">
                <div className="d-flex align-items-start gap-3 listHeading">
                  <img src={icon1} alt="" />
                  <div>
                    <h2 className="mb-0 ">Not Intended For Minors</h2>
                    <p className="">
                      The Website is intended for use by individuals of 18 years
                      of age or older. Persons who are “incompetent to contract”
                      as per Indian Laws, more particularly the Indian Contract
                      Act, 1872 and not willing to agree or abide by the Terms
                      and Conditions mentioned hereunder are not eligible to use
                      the Website. 
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 ">
                <div className="d-flex align-items-start gap-3 listHeading">
                  <img src={icon2} alt="" />
                  <div>
                    <h2 className="mb-0">Acceptance Of Terms</h2>
                    <div className="d-flex align-items-start gap-3  mt-2">
                      <img className="mt-2" src={arrow} alt="" />
                      <p>
                        By accepting these Terms and Conditions, you warrant
                        that you have read and understood all the Terms and
                        Conditions without any impairment in judgment resulting
                        from intoxication or any mental or other health problem
                        that could impair judgment. These Terms and Conditions
                        together with our Information Protection Policy as
                        provided on the Website (“Privacy Policy”) govern your
                        usage of the Website. If you disagree with any part of
                        these Terms and Conditions, please do not use the
                        Website.
                      </p>
                    </div>
                    <div className="d-flex align-items-start gap-3  mt-2">
                      <img className="mt-2" src={arrow} alt="" />
                      <p>
                        By visiting, accessing or registering on this Website,
                        you unconditionally accept to be bound by the Terms and
                        Conditions provided herein below. Access of the Website
                        in any manner, whether automated or otherwise,
                        constitutes use of the Website and your unconditional
                        consent to be bound by these Terms and Conditions. You
                        agree and confirm that the Terms and Conditions may be
                        amended from time to time without notice. Please ensure
                        that you periodically update yourself of the current
                        version of the Terms and Conditions.
                      </p>
                    </div>
                    {/* <div className="d-flex align-items-start gap-3  mt-2">
                                        <img className='mt-2' src={arrow} alt="" />
                                        <p>
                                            Ayana treats the Content you provide as personally identifying information and
                                            administer them based on information protection policy and other associated laws and
                                            regulations.
                                        </p>

                                    </div>
                                    <div className="d-flex align-items-start gap-3  mt-2">
                                        <img className='mt-2' src={arrow} alt="" />
                                        <p>
                                            Ayana may share information you provide with other entity that satisfies information
                                            protection standard for the purpose of system maintenance and any other requirement.
                                        </p>

                                    </div>
                                    <div className="d-flex align-items-start gap-3  mt-2">
                                        <img className='mt-2' src={arrow} alt="" />
                                        <p>
                                            Ayana have taken commercially reasonable care and precautions to ensure that no
                                            viruses / Key Loggers/ Destructive Trojans/ FTP Trojans/ DOS Trojans/ Proxy Trojans/
                                            Software Detection Trojans / Malwares are present on this Website, however, we do not
                                            accept any responsibility for any loss or damage occurring from the use of this Website.
                                        </p>

                                    </div> */}
                    {/* <div className="d-flex align-items-start gap-3  mt-2">
                                        <img className='mt-2' src={arrow} alt="" />
                                        <p>
                                            The Website may use cookies to enhance your experience while using our Website.
                                            Your browser is most likely set to accept cookies. However, if you would prefer not to
                                            receive cookies, you can alter the configuration of your browser to refuse cookies. If you
                                            choose to have your browser refuse such cookies, it is possible that some areas of our
                                            Website will not function properly when you view them.
                                        </p>

                                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-12 ">
                <div className="d-flex align-items-start gap-3 listHeading">
                  <img src={icon3} alt="" />
                  <div>
                    <h2 className="mb-0">User Content Policy</h2>
                    <div className="d-flex align-items-start gap-3  mt-2">
                      <img className="mt-2" src={arrow} alt="" />
                      <p>
                        Accessing the Website and using the features of the
                        Website may require the User to provide certain personal
                        information like name, birth date, gender, address and
                        phone number (hereinafter referred to as “Content”). The
                        User agrees to provide us with true and accurate
                        information with respect to the Content for the purpose
                        of contacting the User.
                      </p>
                    </div>
                    <div className="d-flex align-items-start gap-3  mt-2">
                      <img className="mt-2" src={arrow} alt="" />
                      <p>
                        The User by posting, submitting or displaying Content on
                        or through the Website, and for the purpose of
                        contacting the User, agrees by own free will and choice
                        by clicking the submit button and have further have
                        “opted-in” to accept the information shared via these
                        e-mails. When a User submits his phone number along with
                        his mailing address or to request a call back, the User
                        consents to receive to your order/ request and other
                        Website related communication.
                      </p>
                    </div>

                    <div className="d-flex align-items-start gap-3  mt-2">
                      <img className="mt-2" src={arrow} alt="" />
                      <p>
                        Ayana treats the Content you provide as personally
                        identifying information and administer them based on
                        information protection policy and other associated laws
                        and regulations.
                      </p>
                    </div>

                    <div className="d-flex align-items-start gap-3  mt-2">
                      <img className="mt-2" src={arrow} alt="" />
                      <p>
                        Ayana may share information you provide with other
                        entity that satisfies information protection standard
                        for the purpose of system maintenance and any other
                        requirement.
                      </p>
                    </div>

                    <div className="d-flex align-items-start gap-3  mt-2">
                      <img className="mt-2" src={arrow} alt="" />
                      <p>
                        Ayana have taken commercially reasonable care and
                        precautions to ensure that no viruses / Key Loggers/
                        Destructive Trojans/ FTP Trojans/ DOS Trojans/ Proxy
                        Trojans/ Software Detection Trojans / Malwares are
                        present on this Website, however, we do not accept any
                        responsibility for any loss or damage occurring from the
                        use of this Website.
                      </p>
                    </div>
                    <div className="d-flex align-items-start gap-3  mt-2">
                      <img className="mt-2" src={arrow} alt="" />
                      <p>
                        The Website may use cookies to enhance your experience
                        while using our Website. Your browser is most likely set
                        to accept cookies. However, if you would prefer not to
                        receive cookies, you can alter the configuration of your
                        browser to refuse cookies. If you choose to have your
                        browser refuse such cookies, it is possible that some
                        areas of our Website will not function properly when you
                        view them.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 ">
                <div className="d-flex align-items-start gap-3 listHeading">
                  <img src={icon4} alt="" />
                  <div>
                    <h2 className="mb-0">Restriction For Website Use</h2>
                    <div className="d-flex align-items-start gap-3  mt-2">
                      <img className="mt-2" src={arrow} alt="" />
                      <p>
                        The Users acknowledge that Ayana’s Website is a mere
                        platform for promoting and sharing the information
                        pertaining to renewable sector services undertaken by us
                        and share the same between various Users. The Website
                        has no control over third party content/opinion/advise
                        posted on the Website, which may be available to the
                        Users from time to time.
                      </p>
                    </div>
                    <div className="d-flex align-items-start gap-3  mt-2">
                      <img className="mt-2" src={arrow} alt="" />
                      <p>
                        The Users agree that they shall at all times be governed
                        by the following as applicable from time to time:
                      </p>
                    </div>
                    <div className="mt-2">
                      <ul>
                        <li className="mb-2">
                          The User shall not host, display, upload, modify,
                          publish, transmit, update or share any information or
                          share/list(s) any information or item that belongs to
                          another person and to which the User does not have any
                          right to;
                        </li>
                        <li className="mb-2">
                          Post, submit or disseminate any Content, which is
                          grossly harmful, harassing, blasphemous, defamatory,
                          obscene, pornographic, pedophilic, libelous, invasive
                          of another’s privacy, hateful, or racially, ethnically
                          objectionable, disparaging, relating or encouraging
                          money laundering or gambling, or otherwise unlawful or
                          objectionable in any manner whatever;
                        </li>
                        <li className="mb-2">
                          Submit any Content on the Website, which unlawfully is
                          threatening or unlawfully harassing including but not
                          limited to “indecent representation of women” within
                          the meaning of the Indecent Representation of Women
                          (Prohibition) Act, 1986 or such content which may be
                          harmful to minors;
                        </li>
                        <li className="mb-2">
                          Infringes any patent, trademark, copyright or other
                          proprietary rights or third party trade secrets or
                          rights of publicity or privacy or shall not be
                          fraudulent or involve the sale of counterfeit or
                          stolen items;
                        </li>
                        <li className="mb-2">
                          Violates any law for the time being in force;
                        </li>
                        <li className="mb-2">Impersonates another person;</li>
                        <li className="mb-2">
                          Contains software viruses or any other computer code,
                          files or programs designed to interrupt, destroy or
                          limit the functionality of any computer resource;
                        </li>
                        <li className="mb-2">
                          Threatens the unity, integrity, defense, security or
                          sovereignty of India, friendly relations with foreign
                          states, or public order or causes incitement to the
                          commission of any cognizable offence or prevents
                          investigation of any offence or is insulting any other
                          nation.
                        </li>
                      </ul>
                    </div>
                    <div className="d-flex align-items-start gap-3  mt-2">
                      <img className="mt-2" src={arrow} alt="" />
                      <p>
                        The User also agrees and acknowledges that the Website
                        shall not be used for any of the following purposes:
                      </p>
                    </div>
                    <div className="mt-2">
                      <ul>
                        <li className="mb-2">
                          Transmitting material that encourages conduct that
                          constitutes a criminal offence, result in civil
                          liability or otherwise breaches any relevant laws,
                          regulations or code of practice;
                        </li>
                        <li className="mb-2">
                          Gaining unauthorized access to other computer/ network
                          systems;
                        </li>
                        <li className="mb-2">
                          Making, transmitting or storing electronic copies of
                          materials protected by copyright without the
                          permission of the owner;
                        </li>
                        <li className="mb-2">
                          Attempting to decipher, decompile disassemble or
                          reserve engineer any part of the Website;
                        </li>
                        <li className="mb-2">
                          Copying or duplicating in any manner any of the
                          Content on the website or other information available
                          from the Website;
                        </li>
                        <li className="mb-2">
                          Framing or hot linking or deep linking any Content on
                          the Website;
                        </li>
                        <li className="mb-2">
                          Gain unauthorized access to the Website, or any
                          account, computer system, or network connected to the
                          Website, by means such as hacking, password mining or
                          other illicit means;
                        </li>
                        <li className="mb-2">
                          Gather for marketing purposes any e-mail addresses or
                          other personal information that has been posted by
                          other Users of the Website.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 ">
                <div className="d-flex align-items-start gap-3 listHeading">
                  <img src={icon5} alt="" />
                  <div>
                    <h2 className="mb-0">Limitation Of Liability</h2>
                    <div className="d-flex align-items-start gap-3  mt-2">
                      <p>
                        Ayana assumes no liability and shall not be responsible
                        as to the errors or omissions which may occur sometimes.
                        Ayana expressly disclaim any liability, whether in
                        contract, tort, strict liability or otherwise, for any
                        direct, indirect, incidental, consequential, punitive or
                        special damages arising out of or in any way connected
                        with your access or use or inability to access or use of
                        Ayana website or reliance on its content, or any failure
                        of performance, interruption, defect, delay in
                        transmission, computer viruses or other harmful
                        components, or line or system failure associated with
                        the Ayana website, regardless of our knowledge thereof.
                        Ayana shall not be liable for, any damages to, or
                        viruses that may infect, your computer equipment or
                        other property as a result of your access to, use of, or
                        browsing in the website or your downloading of any
                        materials, data, text, images, video, or audio from this
                        website.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 ">
                <div className="d-flex align-items-start gap-3 listHeading">
                  <img src={icon6} alt="" />
                  <div>
                    <h2 className="mb-0">Disclaimers</h2>

                    <div className="d-flex align-items-start gap-3  mt-2">
                      <img className="mt-2" src={arrow} alt="" />
                      <p>
                        Ayana disclaim all warranties or conditions, whether
                        expressed or implied, (including without limitation
                        implied, warranties or conditions of information and
                        context).
                      </p>
                    </div>
                    <div className="d-flex align-items-start gap-3  mt-2">
                      <img className="mt-2" src={arrow} alt="" />
                      <p>
                        Ayana makes it clear that throughout the Website, there
                        may be provided links and pointers to Internet sites
                        maintained by third parties. Our linking to any such
                        third-party sites does not imply an endorsement or
                        sponsorship of such sites, or the information, products
                        or services offered on or through the sites.
                      </p>
                    </div>
                    <div className="d-flex align-items-start gap-3  mt-2">
                      <img className="mt-2" src={arrow} alt="" />
                      <p>
                        The Website is provided for informational purposes only
                        and is not intended for trading or investing purposes or
                        for commercial use. Any interactions of a User with
                        companies, organizations and/or individuals found on or
                        through our Website, including any purchases,
                        transactions, or other dealings, and any terms,
                        conditions, warranties or representations associated
                        with such dealings, are solely between you and such
                        companies, organizations and/or individuals. The Users
                        agree that Ayana will not be responsible or liable for
                        any loss or damage of any sort incurred as the result of
                        any such dealings.
                      </p>
                    </div>
                    <div className="d-flex align-items-start gap-3  mt-2">
                      <img className="mt-2" src={arrow} alt="" />
                      <p>
                        It is also agreed that, if there is a dispute between
                        users of the Website, or between a User and any third
                        party, we are under no obligation to become involved,
                        and the User agrees to release us and our affiliates
                        from any claims, demands and damages of every kind or
                        nature, known or unknown, suspected and unsuspected,
                        disclosed and undisclosed, arising out of or in any way
                        related to such dispute and/or our Website.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 ">
                <div className="d-flex align-items-start gap-3 listHeading">
                  <img src={icon7} alt="" />
                  <div>
                    <h2 className="mb-0">Intellectual Property Rights</h2>

                    <div className="d-flex align-items-start gap-3  mt-2">
                      <img className="mt-2" src={arrow} alt="" />
                      <p>
                        Unless otherwise indicated or anything contained to the
                        contrary or any proprietary material owned by a third
                        party and so expressly mentioned, Ayana owns and
                        reserves all Intellectual Property Rights in all text,
                        programs, products, processes, technology, content and
                        other material, which appear on this Website.
                      </p>
                    </div>
                    <div className="d-flex align-items-start gap-3  mt-2">
                      <img className="mt-2" src={arrow} alt="" />
                      <p>
                        Access to this Website does not confer and shall not be
                        considered as conferring upon anyone any license under
                        any of our or any third party intellectual property
                        rights. All rights, including copyright, in this Website
                        are owned by or licensed to us.
                      </p>
                    </div>
                    <div className="d-flex align-items-start gap-3  mt-2">
                      <img className="mt-2" src={arrow} alt="" />
                      <p>
                        Ayana names and logos and all related product and
                        service, names, design marks and slogans are the
                        trademarks or service marks of Ayana Renewable Power
                        Private Limited. Access to this Website does not
                        authorize anyone to use any name, logo or mark which
                        appear on the Website in any manner.
                      </p>
                    </div>
                    <div className="d-flex align-items-start gap-3  mt-2">
                      <img className="mt-2" src={arrow} alt="" />
                      <p>
                        All Content available on the Website, is intended solely
                        for personal, non- commercial use. You may download or
                        copy the Contents and other downloadable materials
                        displayed on the Website for your personal use only. No
                        rights, title or interest or interest in any downloaded
                        materials or software is transferred to you as a result
                        of any such downloading or copying. You may not
                        reproduce (except as noted above), publish, transmit,
                        distribute, display, modify, create derivative works
                        from, sell or participate in any sale of or exploit in
                        any way, in whole or in part, any of the Contents, the
                        Website or any related software.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 ">
                <div className="d-flex align-items-start gap-3 listHeading">
                  <img src={icon8} alt="" />
                  <div>
                    <h2 className="mb-0">
                      Modifications Or Discontinuation Of The Website
                    </h2>

                    <div className="d-flex align-items-start gap-3  mt-2">
                      <img className="mt-2" src={arrow} alt="" />
                      <p>
                        Ayana reserves the right at any time and from time to
                        time to modify or discontinue, temporarily or
                        permanently, the Website, or any portion thereof, with
                        or without notice. The User agree that Ayana will not be
                        liable to any User or to any third party for any
                        modification, suspension or discontinuance of the
                        Website or any portion thereof.
                      </p>
                    </div>
                    <div className="d-flex align-items-start gap-3  mt-2">
                      <img className="mt-2" src={arrow} alt="" />
                      <p>
                        Ayana may amend any content on its Website at any time
                        by posting a revised version on the Website. All updates
                        and amendments shall be notified to you via Ayana
                        Website. The revised version of the User Agreement will
                        be effective at the time Ayana posts it on the Website.
                        The Users are advised to regularly check for any
                        amendments or updates to the Terms and Conditions
                        contained in this User Agreement.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 ">
                <div className="d-flex align-items-start gap-3 listHeading">
                  <img src={icon9} alt="" />
                  <div>
                    <h2 className="mb-0">Entire Agreement</h2>

                    <div className="d-flex align-items-start gap-3  mt-2">
                      <p>
                        If any part of this User Agreement is determined to be
                        invalid or unenforceable pursuant to applicable law
                        including, but not limited to, the warranty disclaimers
                        and liability limitations set forth above, then the
                        invalid or unenforceable provision will be deemed to be
                        superseded by a valid, enforceable provision that most
                        closely matches the intent of the original provision and
                        the remainder of the User Agreement shall continue in
                        effect. Unless otherwise specified herein, this User
                        Agreement constitutes the entire agreement between you
                        and us with respect to the Website/ Service and it
                        supersedes all prior or contemporaneous communications
                        and proposals, whether electronic, oral or written,
                        between you and us with respect to the Website/ Service.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 ">
                <div className="d-flex align-items-start gap-3 listHeading">
                  <img src={icon10} alt="" />
                  <div>
                    <h2 className="mb-0">Applicable Laws</h2>

                    <div className="d-flex align-items-start gap-3  mt-2">
                      <p>
                        The use of this Website and any Term and Conditions,
                        Privacy Policy or any other document stated in this User
                        Agreement is subject to laws of India. In case of any
                        disputes arising out of the use of the Website, Courts
                        of Bangalore will have exclusive jurisdiction.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 ">
                <div className="d-flex align-items-start gap-3 listHeading">
                  <img src={icon11} alt="" />
                  <div>
                    <h2 className="mb-0">Waiver</h2>

                    <div className="d-flex align-items-start gap-3  mt-2">
                      <p>
                        No delay or omission by Ayana to exercise any right
                        occurring upon any noncompliance on your part with
                        respect to any of these Terms shall impair any such
                        right or power or be construed to be a waiver thereof.
                        Any waiver by Ayana of any of the covenants, conditions,
                        or agreements to be performed by you shall not be
                        construed to be a waiver of any succeeding breach
                        thereof or of any covenant, condition, or agreement
                        herein contained.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 ">
                <div className="d-flex align-items-start gap-3 listHeading">
                  <img src={icon12} alt="" />
                  <div>
                    <h2 className="mb-0">Survival</h2>

                    <div className="d-flex align-items-start gap-3  mt-2">
                      <p>
                        The provisions herein dealing directly or indirectly
                        with disclaimers of warranties, limitation of liability,
                        compliance with laws and regulations, dispute resolution
                        and use restrictions and prohibitions continue to apply
                        and shall survive the termination or expiration of any
                        relationship between you and Ayana.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default TermsAndConditions;
