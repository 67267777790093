import React from "react";
import "./assests-component.scss";
import Navbar from "../../components/Navbar/Navbar";

import bi1 from "../../assets/img/bi1.png";
import bi2 from "../../assets/img/bi2.png";
import bi3 from "../../assets/img/bi3.png";
import bi4 from "../../assets/img/bi4.png";
import bi5 from "../../assets/img/bi5.png";
import bi6 from "../../assets/img/bi6.png";
import bi7 from "../../assets/img/bi7.png";
import bi8 from "../../assets/img/bi8.png";
import bi9 from "../../assets/img/bi9.png";
import bi10 from "../../assets/img/bi10.png";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
import Navigation from "../../components/Naigation/Navigation";

const AssetsComponent = () => {
  return (
    <div className="assets">
      <Navigation />
      <section className="governance_breadcrumb" id={"goverence"}>
        <div className="container-fluid">

        </div>
      </section>
      <div className="breadcrumb_text">
        <h4 className="breadcrumb_title">Assets</h4>
        <p className="breadcrumb_link"> <Link to="/"> Home</Link> |  Assets</p>
      </div>
      <section>
        <div className="row mt-4 justify-content-center m-4">
          <div className="col-md-3">
            <div className="bi_shadow_box bg-white p-3">
              <img src={bi1} className="img-fluid" alt="" />
              <div className="row mt-3">
                <div className="col-md-10">
                  <p>Code of Conduct Policy</p>
                </div>
                <div className="col-md-2 text-center">
                  <a className="pdf_link" href="xyz.pdf" target="_blank">
                    <PictureAsPdfIcon />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="bi_shadow_box bg-white p-3">
              <img src={bi2} className="img-fluid" alt="" />
              <div className="row mt-3">
                <div className="col-md-10">
                  <p>Anti-Bribery and Anti-Corruption Policy</p>
                </div>
                <div className="col-md-2 text-center">
                  <a className="pdf_link" href="xyz.pdf" target="_blank">
                    <PictureAsPdfIcon />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="bi_shadow_box bg-white p-3">
              <img src={bi3} className="img-fluid" alt="" />
              <div className="row mt-3">
                <div className="col-md-10">
                  <p>Whistle Blower Policy</p>
                </div>
                <div className="col-md-2 text-center">
                  <a className="pdf_link" href="xyz.pdf" target="_blank">
                    <PictureAsPdfIcon />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="bi_shadow_box bg-white p-3">
              <img src={bi4} className="img-fluid" alt="" />
              <div className="row mt-3">
                <div className="col-md-10">
                  <p>POSH Policy</p>
                </div>
                <div className="col-md-2 text-center">
                  <a className="pdf_link" href="xyz.pdf" target="_blank">
                    <PictureAsPdfIcon />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 mt-3">
            <div className="bi_shadow_box bg-white p-3">
              <img src={bi5} className="img-fluid" alt="" />
              <div className="row mt-3">
                <div className="col-md-10">
                  <p>Supplier's code of conduct</p>
                </div>
                <div className="col-md-2 text-center">
                  <a className="pdf_link" href="xyz.pdf" target="_blank">
                    <PictureAsPdfIcon />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 mt-3">
            <div className="bi_shadow_box bg-white p-3">
              <img src={bi6} className="img-fluid" alt="" />
              <div className="row mt-3">
                <div className="col-md-10">
                  <p>Grievance</p>
                </div>
                <div className="col-md-2 text-center">
                  <a className="pdf_link" href="xyz.pdf" target="_blank">
                    <PictureAsPdfIcon />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 mt-3">
            <div className="bi_shadow_box bg-white p-3">
              <img src={bi7} className="img-fluid" alt="" />
              <div className="row mt-3">
                <div className="col-md-10">
                  <p>Security Policy Statement</p>
                </div>
                <div className="col-md-2 text-center">
                  <a className="pdf_link" href="xyz.pdf" target="_blank">
                    <PictureAsPdfIcon />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 mt-3">
            <div className="bi_shadow_box bg-white p-3">
              <img src={bi8} className="img-fluid" alt="" />
              <div className="row mt-3">
                <div className="col-md-10">
                  <p>Labour-and-Working-Condition-Policy</p>
                </div>
                <div className="col-md-2 text-center">
                  <a className="pdf_link" href="xyz.pdf" target="_blank">
                    <PictureAsPdfIcon />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 mt-3">
            <div className="bi_shadow_box bg-white p-3">
              <img src={bi9} className="img-fluid" alt="" />
              <div className="row mt-3">
                <div className="col-md-10">
                  <p>Gender Balance and Diversity Policy</p>
                </div>
                <div className="col-md-2 text-center">
                  <a className="pdf_link" href="xyz.pdf" target="_blank">
                    <PictureAsPdfIcon />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 mt-3">
            <div className="bi_shadow_box bg-white p-3">
              <img src={bi10} className="img-fluid" alt="" />
              <div className="row mt-3">
                <div className="col-md-10">
                  <p>Community-Development-Framework</p>
                </div>
                <div className="col-md-2 text-center">
                  <a className="pdf_link" href="xyz.pdf" target="_blank">
                    <PictureAsPdfIcon />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default AssetsComponent;
