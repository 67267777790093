// axiosConfig.js

import axios from "axios";
import { logout } from "./helper";
import Cookies from "js-cookie";

const URL = "http://localhost:8081/";
// const URL = "https://savvinova.photostop.in/backend/";

// Create an instance of axios with custom configurations
const instance = axios.create({
  baseURL: URL, // Base URL for your API
  timeout: 100000, // Request timeout in milliseconds
  headers: {
    "Content-Type": "application/json", // Set default content type
  },
});

const getTokenFromCookies = () => {
  return Cookies.get("token"); // Replace 'token' with the actual cookie name
};

// Optionally, you can intercept requests or responses
instance.interceptors.request.use(async (config) => {
  const token = getTokenFromCookies();

  if (token) {
    config.headers["Authorization"] = `${token}`;
  }

  config.headers["Content-Type"] = "application/json";
  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    console.log(error?.response?.data, "response");
    // Check if response status is 400 and message is "Token has expired"
    if (
      error.response.status === 401 &&
      error.response.data.error === "Invalid token"
    ) {
      // Redirect to login page
      console.log("Invalid token ");
      logout();
      console.log("401");
      window.location.href = "/admin/login";
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export default instance;
