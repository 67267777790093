import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import parse from "html-react-parser";

import Spinner from "../Spinner/Spinner";
import "./blog-pagination.scss";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import config from "../../config";

const BASE_URL = config.base_url;

const uploadUrl = `${BASE_URL}storage/blog/`;
const getBlogsUrl = `${BASE_URL}api/get-blogs`;


function BlogItems() {
  const navigate = useNavigate();

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  
  useEffect(() => {
    fetch(getBlogsUrl)
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  const handleReadMore = (data) => {
    // {`/blog-detail/${data.slug}
    navigate("/blog-detail/" + data.slug, { state: { blogData: data } });
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return (
      <div>
        <Spinner />
      </div>
    );
  } else {
    return (
      <div className="px-4">
        <section className="row">
          {items.data.map((data) => {
            return (
              <div
                className="col-12 col-sm-12 col-md-6 col-xl-6 mb-4"
                key={data.id}
              >
                <div className="card h-100">
                {
                  data.image && (
                    <img
                    className="blogImgeContainer w-100"
                    src={`${uploadUrl}${data.image}`}
                    alt="blog"
                  />
                  )
                }
           
                  <div className="textContainerBlog p-4">
                    <h6 className="blogHeading">
                      {parse(data.title.slice(0, 60))}
                    </h6>
                    <p className="blogDescription">
                      {parse(data.paragraph.slice(0, 130))}
                    </p>
                    <p className="date mt-2">
                      Posted: {moment(data.published_at).format("DD MMM, YYYY")}
                    </p>
                    <div className="buttonContainer">
                      <button onClick={() => handleReadMore(data)}>
                        Read More
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </section>
      </div>
    );
  }
}
export default function BlogPagination({ itemsPerPage }) {
  // We start with an empty list of items.
  const [items, setItems] = useState([]);
  const [blogData, setBlogData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets

  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setBlogData(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <>
      <BlogItems blogData={blogData} />
      <ReactPaginate
        nextLabel="Next"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="Prev"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
    </>
  );
}
//  b vb vb
