import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import parse from "html-react-parser";

import location from "../../assets/img/careers-images/location.svg";
import time from "../../assets/img/careers-images/time.svg";
import linkedIn from "../../assets/img/careers-images/linkedin.svg";

import "./job-desciption.scss";
import Navbar from "../Navbar/Navbar";
import Spinner from "../Spinner/Spinner";
import Footer from "../Footer/Footer";
import { useParams } from "react-router-dom";
// import JobDetail from "../../routes/job-detail/job-detail";
import { Link } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import moment from "moment";

import config from "../../config";

const BASE_URL = config.base_url;


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const JobDescription = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [item, setitem] = useState([]);
  const [lists, setLists] = useState([]);
  const { slug } = useParams();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    fetch(`${BASE_URL}api/get-job/${slug}`)
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setitem(result);
          console.log(result.job);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );

    fetch(`${BASE_URL}api/get-jobs/`)
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setLists(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, [slug]);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return (
      <div>
        <Navbar />
        <Spinner />
        <Footer />
      </div>
    );
  } else {
    return (
      <section className="job-details ">
        <div style={{ overflow: "hidden" }}>
          <Navbar />
          <div
            className="container text-center"
            style={{ backgroundColor: "#E3E3E3" }}
          >
            {/* <JobDetail /> */}
            <div className="row align-item-start justify-content-evenly pb-3 pt-3">
              <div className="col-12 col-sm-12 col-md-12 col-xl-7 mb-4">
                {item.job ? (
                  <div className="descriptionContainer">
                    <div className="d-flex flex-column flex-md-row">
                      <h2 className="d-flex jobTitle text-start pe-3">
                        {item.job.name}
                      </h2>
                      <div className="ml-md-auto">
                        <Link to={`/apply-now/${item.job.slug}`}>
                          <button className="btn btn_blue">Apply Now</button>
                        </Link>
                      </div>
                    </div>
                    <div className=" locationContainer">
                      <div className="d-flex pb-2 mt-2 flex-column flex-md-row justify-content-between">
                        <p className="text-left">
                          <img src={location} alt="location" />
                          &nbsp;
                          {item.job.location.name}
                        </p>
                        <p className="text-left">
                          <img src={time} alt="location" />
                          &nbsp; Posted:{" "}
                          {moment(item.job.created_at).format("DD MMM, YYYY")}
                        </p>
                      </div>
                    </div>
                    <Box>
                      <div style={{ border: "1px solid lightgray" }}>
                        <Box>
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                          >
                            <Tab
                              className="jobTabs col-12 col-sm-12 col-md-3 col-xl-3"
                              label="job description"
                              {...a11yProps(0)}
                            />
                            <Tab
                              className="jobPoints col-12 col-sm-12 col-md-3 col-xl-3"
                              label="highlights"
                              {...a11yProps(1)}
                            />
                            <Tab
                              className="jobPoints col-12 col-sm-12 col-md-3 col-xl-3"
                              label="job responsibilities"
                              {...a11yProps(2)}
                            />
                            <Tab
                              className="jobPoints col-12 col-sm-12 col-md-3 col-xl-33"
                              label="ABOUT AYANA"
                              {...a11yProps(3)}
                            />
                          </Tabs>
                        </Box>
                      </div>
                      <>
                        <TabPanel value={value} index={0}>
                          <h2 className="jobHighlights">Highlights</h2>{" "}
                          <div className="d-flex flex-wrap gap-2">
                            {JSON.parse(item.job.skills).map((skill) => {
                              return (
                                <p
                                  style={{ cursor: "auto" }}
                                  className="btn jobButton"
                                  key={skill.value}
                                >
                                  {skill.value}
                                </p>
                              );
                            })}
                          </div>
                          <div className="jobPoints my-3">
                            Excellent academic track record and technical acumen
                          </div>
                          {/* <h2 className="jobHighlights">Job Description</h2> */}

                          <div className="jobPoints">
                            {parse(item.job.description)}
                          </div>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                          {/* <div className="container text-left jobType">
                            <div className="row">
                              <div className="col">Type</div>
                              <div className="col">Years Experience</div>
                              <div className="col">Employment </div>
                              <div className="col">Total Vacancies</div>
                            </div>
                          </div> */}
                          <div className="container text-left jobPoints">
                            <div className="row g-3" key={item.job.id}>
                              <div className="col-6 col-md-3">
                                <p className="fw-bold">Type</p>
                                <p>{item.job.type}</p>
                              </div>
                              <div className="col-6 col-md-3">
                                <p className="fw-bold">Years Experience</p>
                                <p>{item.job.experience}</p>
                              </div>
                              <div className="col-6 col-md-3">
                                <p className="fw-bold">Employment</p>
                                <p>{item.job.employment}</p>
                              </div>
                              <div className="col-6 col-md-3">
                                <p className="fw-bold">Total Vacancies</p>
                                <p>{item.job.number_of_vacancies}</p>
                              </div>
                            </div>
                          </div>
                        </TabPanel>

                        <TabPanel value={value} index={2}>
                          <div className="jobResponsibilities">
                            <h2 className="jobHeading">
                              {item.job.jobResponsibilities}
                            </h2>
                            <h2 className="jobSubheading">
                              {item.job.jobResponsibilitiesHeading}
                            </h2>
                            <ul className="jobPara p-0">
                              <li>{parse(item.job.responsibilities)}</li>
                            </ul>
                          </div>
                        </TabPanel>

                        <TabPanel value={value} index={3}>
                          <h2 className="aboutCompany">About The Company</h2>
                          <p className="companyDescription">
                            We are a utility-scale renewable energy platform
                            dedicated to expanding India’s renewable energy
                            footprint, helping it achieve its 500 GW renewable
                            energy target by 2030.
                          </p>

                          <div className="shareButton d-flex">
                            <a
                              className="d-flex align-item-center gap-2"
                              href="https://www.linkedin.com/sharing/share-offsite/?url={url}"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img src={linkedIn} alt="" />
                              <p className="m-0">Share</p>
                            </a>
                          </div>

                          <div key={item.job.id}>
                            <NavHashLink to={`/apply-now/${item.job.slug}`}>
                              <button className="btn btn_blue job_btn">
                                Apply Now
                              </button>
                            </NavHashLink>
                          </div>
                        </TabPanel>
                      </>
                    </Box>
                  </div>
                ) : (
                  <div>
                    <h2>Not found</h2>
                  </div>
                )}
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-xl-4 descriptionContainer">
                <h2 className="jobTitle text-left">Job Openings</h2>
                {lists.data &&
                  lists.data.slice(0, 5).map((data) => (
                    <>
                      <div
                        className="d-flex justify-content-between jobContainer"
                        key={data.id}
                      >
                        <h2 className="aboutCompany">{data.name}</h2>
                        <Link to={`/job-details/${data.slug}`}>
                          <button className="btn btn_blue job_btn w-75 p-4 ms-4">
                            View Job
                          </button>
                        </Link>
                      </div>
                    </>
                  ))}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </section>
    );
  }
};
export default JobDescription;
