import React, { useState } from "react";
import { Link } from "react-router-dom";
import { logout } from "../../network/helper";

import config from '../../config';

const BASE_URL = config.base_url;

const AdminLayout = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  return (
    <div className="d-flex" id="wrapper">
      {/* Sidebar */}
      <div className="border-end bg-white " id="sidebar-wrapper">
        <div className="sidebar-heading border-bottom bg-light">
          <img
            alt="ayana_power"
            src={`${BASE_URL}assets/img/logo.svg`}
            className="img-fluid w-100"
          />
        </div>
        <div className="list-group list-group-flush">
          <Link
            className="list-group-item list-group-item-action list-group-item-light p-3"
            to="/admin/dashboard"
          >
            Dashboard
          </Link>
          <Link
            className="list-group-item list-group-item-action list-group-item-light p-3"
            to="/admin/locations"
          >
            Location
          </Link>
          <Link
            className="list-group-item list-group-item-action list-group-item-light p-3"
            to="/admin/job-opening"
          >
            Job Opening
          </Link>
          <Link
            className="list-group-item list-group-item-action list-group-item-light p-3"
            to="/admin/team-member"
          >
            Team Member
          </Link>
          <Link
            className="list-group-item list-group-item-action list-group-item-light p-3"
            to="/admin/blogs"
          >
            Blogs
          </Link>
          <Link
            className="list-group-item list-group-item-action list-group-item-light p-3"
            to="/admin/admin"
          >
            Admin
          </Link>
          <Link
            className="list-group-item list-group-item-action list-group-item-light p-3"
            to="/admin/applied-jobs"
          >
            Applied for jobs
          </Link>
          <Link
            className="list-group-item list-group-item-action list-group-item-light p-3"
            to="/admin/vendor-listing"
          >
            Vendor Listing
          </Link>
          <Link
            className="list-group-item list-group-item-action list-group-item-light p-3"
            to="/admin/enquiry"
          >
            Enquiry's
          </Link>
          <Link
            className="list-group-item list-group-item-action list-group-item-light p-3"
            to="/admin/blogs-comments"
          >
            Blogs Comments
          </Link>
          <Link
            className="list-group-item list-group-item-action list-group-item-light p-3"
            to="/admin/linkedin-posts"
          >
            LinkedIn Posts
          </Link>
          <Link
            className="list-group-item list-group-item-action list-group-item-light p-3"
            to="/login"
            onClick={() => {
              logout();
            }}
          >
            Log-out
          </Link>
        </div>
      </div>

      {/* Page content wrapper */}
      <div className="w-100" id="page-content-wrapper">
        {/* Page content */}
        <div className="container-fluid ">{children}</div>
      </div>
    </div>
  );
};

export default AdminLayout;
