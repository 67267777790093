import React from "react";
import "./sitemap.scss";

import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import QHSE from "../../assets/pdf/QHSE.pdf";

const CsrInitiative = () => {
  return (
    <div className="sitemap" id="sitemap">
      <Navbar />
      {/* <section className="csr_breadcrumb" id={"csr"}>
        <div className="container-fluid"></div>
      </section>
      <div className="breadcrumb_text">
        <h4 className="breadcrumb_title">Community Development</h4>
        <p className="breadcrumb_link">
          <Link to="/"> Home</Link> | CSR Initiatives
        </p>
      </div> */}
      <div className="container text-center mt-5">
        <h2>SITEMAP</h2>
        <hr />
      </div>
      <section>
        <div className="container">
          <div className="row g-3">
            <div className="col-md-3">
              <p className="head_title text-center bg_blue text-white p-2 w-100 shadow-lg">
                ABOUT
              </p>
              <ul className="py-2 shadow-lg">
                <Link className="text-dark" to="/about">
                  <li>About</li>
                </Link>
              </ul>
              <p className="head_title text-center bg_blue text-white p-2 w-100 shadow-lg mt-3">
                CAREERS
              </p>
              <ul className="py-2 shadow-lg">
                <Link className="text-dark" to="/careers">
                  <li>Work With Us</li>
                </Link>
                <Link className="text-dark" to="/ayana-life">
                  <li>Life @ Ayana</li>
                </Link>
              </ul>
            </div>
            <div className="col-md-3">
              <p className="head_title text-center bg_blue text-white p-2 w-100 shadow-lg">
                OFFERINGS &amp; PROJECTS
              </p>
              <ul className="py-2 shadow-lg">
                <Link to={`/offerings`}>
                  <li className="text-dark">Our Offerings</li>
                </Link>
                {/* <NavHashLink
                  activeclassname="is-active"
                  to={`/offerings#projects`}
                >
                  <li className="text-dark">Projects</li>
                </NavHashLink> */}
              </ul>
              <p className="head_title text-center bg_blue text-white p-2 w-100 shadow-lg mt-3">
                MEDIA
              </p>
              <ul className="py-2 shadow-lg">
                <Link className="text-dark" to="/media">
                  <li>Latest News</li>
                </Link>
                <Link className="text-dark" to="/blogs">
                  <li>Blogs</li>
                </Link>
              </ul>
            </div>
            <div className="col-md-3">
              <p className="head_title text-center bg_blue text-white p-2 w-100 shadow-lg">
                SUSTAINABILITY
              </p>
              <ul className="py-2 shadow-lg">
                <Link className="text-dark" to="/sustainability">
                  <li>Sustainability</li>
                </Link>
                <a href={QHSE} target="_blank">
                  <li className="text-dark">QHSE</li>
                </a>
                <Link className="text-dark" to="/governance">
                  <li>Corporate Governance</li>
                </Link>
                <Link className="text-dark" to="/csr-initiative">
                  <li>Community development</li>
                </Link>
              </ul>
            </div>
            <div className="col-md-3">
              <p className="head_title text-center bg_blue text-white p-2 w-100 shadow-lg">
                PARTNERSHIP
              </p>
              <ul className="py-2 shadow-lg">
                <Link className="text-dark" to="/partners">
                  <li>Partners</li>
                </Link>
                <Link className="text-dark" to="/suppliers">
                  <li>Suppliers</li>
                </Link>
              </ul>
              <p className="head_title text-center bg_blue text-white p-2 w-100 shadow-lg mt-3">
                CONTACT US
              </p>
              <ul className="py-2 shadow-lg">
                <Link className="text-dark" to="/contact">
                  <li>Contact</li>
                </Link>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default CsrInitiative;
