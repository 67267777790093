import React, { useEffect, useState } from "react";

import "./home-slider.scss";
import mapIcon from "../../assets/mapIcon.svg";
import experienceIcon from "../../assets/experience.svg";
import legalIcon from "../../assets/legal.svg";
import { Link } from "react-router-dom";
import Spinner from "../Spinner/Spinner";
import moment from "moment";

import config from "../../config";

const BASE_URL = config.base_url;

const getJobsUrl = `${BASE_URL}api/get-jobs/`;


export default function HomeSlider() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetch(getJobsUrl)
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          console.log(result.data[0].skills, "data");
          setItems(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return (
      <div>
        <Spinner />
      </div>
    );
  } else {
    return (
      <>
        <div className="container">
          <div className="row mt-3 g-3 justify-content-center">
            {items.data.slice(0, 3).map((data) => (
              <div className="col-10 col-sm-10 col-md-4 col-xl-4">
                <div className=" shadow-lg p-3 pb-5 job-card position-relative">
                  <h4 className="jobTitle fs-5">{data.name}</h4>
                  <p>
                    Posted on:{" "}
                    <span>
                      {moment(data.created_at).format("DD MMM, YYYY")}
                    </span>
                  </p>
                  <hr />

                  <div className="row jobLocation">
                    <div className="col-12 col-sm-12 col-md-12 col-xl-12">
                      <div className="d-flex flex-wrap gap-4">
                        <p class="d-flex gap-2">
                          <img src={mapIcon} alt="location" />
                          {data.location.name}
                        </p>
                        <p class="d-flex gap-2">
                          <img src={legalIcon} alt="location" />
                          {data.type}
                        </p>
                        <p class="d-flex gap-2">
                          <img src={experienceIcon} alt="location" />
                          {data.experience}
                        </p>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-xl-6 mt-3">
                      <Link to={`/job-details/${data.slug}#`}>
                        <button className="btn btn_blue position-absolute bottom-0 mb-2">
                          View Details
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
}
