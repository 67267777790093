import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import "./blogs.scss";
import BlogPagination from "../../components/BlogPagination/BlogPagination";
import Navbar from "../../components/Navbar/Navbar";
import blog_banner from "../../assets/img/blogs/blog_banner.jpg";

const Blog = () => {
  return (
    <>
      <Navbar />
      <div className="blogContainer">
        <section
          className="pt-0 pb-0 position-relative banner_image_overlay"
          id={"blog"}
        >
          <img src={blog_banner} alt="" className="img-fluid w-100" />
          <div className="breadcrumb_text">
            <h4 className="breadcrumb_title">Blogs</h4>
            <p className="breadcrumb_link">
              <Link to="/"> Home</Link> | Blogs
            </p>
          </div>
        </section>

        <section className="" id={"blogs"}>
          <center>
            <div className="title pt-4">
              <h4>Latest BLOGS </h4>
              {/* <p className='blogText'>We value our partners. We believe collaboration is an integral part of accelerating sustainable development</p> */}
            </div>
          </center>
        </section>
        <BlogPagination itemsPerPage={4} />
      </div>
      <Footer />
    </>
  );
};
// }
export default Blog;
