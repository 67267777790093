import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import Footer from "../../components/Footer/Footer";
import "./apply-now.scss";
import Navbar from "../../components/Navbar/Navbar";
import PreloaderForm from "../../components/PreloaderForm/PreloaderForm";
import Swal from 'sweetalert2';
// toastify
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";


import config from "../../config";

const BASE_URL = config.base_url;

const uploadUrl = `${BASE_URL}api/apply-job`;


const ApplyNow = () => {
  const navigate = useNavigate();
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [experience, setExperience] = useState("");
  const [previous_company, setPreviousCompany] = useState("");
  const [presently_working, setPresentlyWorking] = useState("");
  const [current_ctc, setCurrentCtc] = useState("");
  const [willing_to_relocate, setWillingToRelocate] = useState("");
  const [notice_period, setNoticePeriod] = useState("");
  const [level_of_education, setLevelOfEducation] = useState("");
  const [college_name, setCollegeName] = useState("");
  const [resume, setResume] = useState(null);
  const { id } = useParams();
  const [job_id, setJobId] = useState(id);
  const [isLoading, setIsLoading] = useState(false);
  const [jobAppliedId , setJobAppliedId] = useState("");

  async function registerUser(event) {
    event.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("email", email);
    formData.append("phone_number", phone_number);
    formData.append("experience", experience);
    formData.append("previous_company", previous_company);
    formData.append("presently_working", presently_working);
    formData.append("current_ctc", current_ctc);
    formData.append("willing_to_relocate", willing_to_relocate);
    formData.append("notice_period", notice_period);
    formData.append("level_of_education", level_of_education);
    formData.append("college_name", college_name);
    formData.append("resume", resume);
    formData.append("job_id", job_id);
   

    const response = await fetch(
      uploadUrl,
      {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
        },

        body: formData,
      }
    );

    const data = await response.json();
   
    if(data.status === 202){

      setJobAppliedId(data.job_applied_id); 

      Swal.fire({
        title: "Job Apply",
        text: data.msg,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Re-submit"
      }).then((result) => {
        if (result.isConfirmed) {
          resumit(data.job_applied_id)
          Swal.fire({
            title: "Re-submited",
            text: "Are you sure you want to re-submit?",
            icon: "success"
          });

        } 
        else if (result.isDismissed) {
          setIsLoading(false);
        }
      });
    }  
    else  if (data.status === 201) {
      navigate("/thankyou?s=career");
    }
  }

  const notify = function() {
    toast.warning('Resume File Should be Less than 2 MB');
  }

 async  function resumit(id){

    setIsLoading(true);
    const formData = new FormData();
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("email", email);
    formData.append("phone_number", phone_number);
    formData.append("experience", experience);
    formData.append("previous_company", previous_company);
    formData.append("presently_working", presently_working);
    formData.append("current_ctc", current_ctc);
    formData.append("willing_to_relocate", willing_to_relocate);
    formData.append("notice_period", notice_period);
    formData.append("level_of_education", level_of_education);
    formData.append("college_name", college_name);
    formData.append("resume", resume);
    formData.append("job_id", job_id);
    formData.append("applied_job_id" , id);

    const response = await fetch(
      uploadUrl,
      {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
        },
         body: formData,
      }
    );

    const data = await response.json();
   
    if(data.status === 202){
      alert(data);
    }  
    else  if (data.status === 201) {
      navigate("/thankyou?s=career");
    }
   }

  return (
    <div>
      <Navbar />

      <section className="applyNowContainer mb-4 mt-3" id="apply-now">
        <div className="text-center ">
          <h4>Job Application Form</h4>
        </div>
        <div className=" text-center">
          <form className="" onSubmit={registerUser}>
            <div className="">
              <div className="container ">
                <div className="row ">
                  <div className="col-md-6">
                    <div className="">
                      <input
                        value={first_name}
                        onChange={(e) => setFirstName(e.target.value)}
                        className="inputForm form-control"
                        type="text"
                        id="firstname"
                        name="firstname"
                        placeholder="First Name"
                        minLength={3}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="">
                      <input
                        value={last_name}
                        onChange={(e) => setLastName(e.target.value)}
                        className="inputForm form-control"
                        type="text"
                        placeholder="Last Name"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="container ">
                <div className="row ">
                  <div className="col-md-6">
                    <div className="">
                      <input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="inputForm form-control"
                        type="email"
                        placeholder="Email"
                        required
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="">
                      <input
                        value={phone_number}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        className="inputForm form-control"
                        type="tel"
                        placeholder="Phone Number"
                        pattern="[1-9]{1}[0-9]{9}"
                        title="Number should not be less than 10 or more"
                        maxlength="10"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="container text-center">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div className="">
                      <div className="">
                        <select
                          name=""
                          value={experience}
                          onChange={(e) => setExperience(e.target.value)}
                          className="inputForm form-control dropDown"
                          required
                        >
                          <option option selected disabled value="">
                            Experience
                          </option>
                          <option>0-1 years</option>
                          <option>1-2 years </option>
                          <option>2-3 Years</option>
                          <option>3 and above</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="">
                      <select
                        name="status"
                        value={level_of_education}
                        onChange={(e) => setLevelOfEducation(e.target.value)}
                        className="inputForm form-control dropDown"
                        required
                      >
                        <option option selected disabled value="">
                          Level Of Education
                        </option>
                        <option>10+2 </option>
                        <option>Under Graduation</option>
                        <option>Post Graduation</option>
                        <option>PhD</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container ">
                <div className="row ">
                  <div className="col-md-6">
                    {/* college */}
                    <div className="">
                      <input
                        value={college_name}
                        onChange={(e) => setCollegeName(e.target.value)}
                        className="inputForm form-control"
                        type="text"
                        placeholder="College Name"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="">
                      <div className="">
                        <select
                          name="working"
                          value={presently_working}
                          onChange={(e) => setPresentlyWorking(e.target.value)}
                          className="inputForm form-control dropDown"
                          required
                        >
                          <option option selected disabled value="">
                            Presently Working
                          </option>
                          <option>Yes</option>
                          <option>No</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container ">
                <div className="row ">
                  <div className="col-md-6">
                    <div className="">
                      <div className="">
                        <select
                          name="relocate"
                          value={willing_to_relocate}
                          onChange={(e) => setWillingToRelocate(e.target.value)}
                          className="inputForm form-control dropDown"
                          required
                        >
                          <option option selected disabled value="">
                            Willing to Relocate
                          </option>
                          <option>Yes</option>
                          <option>No</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className=" ">
                      <input
                        value={previous_company}
                        onChange={(e) => setPreviousCompany(e.target.value)}
                        className="inputForm form-control"
                        type="text"
                        placeholder="Previous Company"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="container ">
                <div className="row ">
                  <div className="col-md-6">
                    <div className="">
                      <input
                        value={current_ctc}
                        onChange={(e) => setCurrentCtc(e.target.value)}
                        className="inputForm form-control"
                        type="number"
                        placeholder="Current Ctc"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="">
                      <div className="">
                        <select
                          name="notice"
                          value={notice_period}
                          onChange={(e) => setNoticePeriod(e.target.value)}
                          className="inputForm form-control dropDown"
                          required
                        >
                          <option option selected disabled value="">
                            Notice Period
                          </option>
                          <option>15 Days</option>
                          <option>30 Days</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container ">
                <div className="row ">
                  <div className="col-md-6">
                    <div className="input-group custom-file inputForm form-control ml-4 ">
                      <label className="pe-2 align-self-center" for="file-name">
                        Upload Your Resume (upto 2MB)
                        <span className="text-success px-2">
                          {" "}
                          {resume == null ? "" : "uploaded successfully"}
                        </span>
                      </label>

                      <ToastContainer />

                      <input
                        type="file"
                        className="form-control align-self-center"
                        id="file-name"
                        accept=".pdf,.doc,.docx"
                        onChange={(e) => {
                          // max file size 2.1 mb 
                          if(e.target.files[0].size > 2048000) {
                            notify();
                            setResume(null);
                          } else {
                            setResume(e.target.files[0]);
                          }
                        }}
                        style={{ cursor: "pointer" }}
                        required
                      />
                    </div>
                  </div>

                  <div className="col preloaderbtn">
                    {isLoading && (
                      <div className="preloaderbtnSpin mr-2 mt-1">
                        <PreloaderForm />
                      </div>
                    )}
                    <input
                      disabled={isLoading}
                      type="submit"
                      value="submit"
                      className="submitButton "
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default ApplyNow;
