import "./App.css";
import Footer from "./components/Footer/Footer";
import Home from "./components/Home/Home";
import Navbar from "./components/Navbar/Navbar";
import { ClearBrowserCache } from "react-clear-browser-cache";

function App() {
  return (
    <>
      <div className="App">
        <ClearBrowserCache>{(contextValue) => null}</ClearBrowserCache>;
        <Navbar />
        <Home />
        <Footer />
      </div>
    </>
  );
}

export default App;
