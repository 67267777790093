
const domainName = window.location.hostname;

let base_url ;

switch(domainName) {
  case "localhost": {
    base_url = "http://localhost:8000/"; // change this if you are running laravel server on diff port
    break;
  }
  case "ayana.photostop.in": {
    base_url = "https://backend-ayana.photostop.in/";
    break;
  }
  case "www.ayanapower.com":
    case "ayanapower.com": {
    base_url = "https://backend.ayanapower.com/";
    break; 
  }
}



export default {
  STORE_KEY: "a56z0fzrNpl^2",
  base_url: base_url,
  COLOR: {
    ORANGE: "#C50",
    DARKBLUE: "#0F3274",
    LIGHTBLUE: "#6EA8DA",
    DARKGRAY: "#999",
  },
};


  // base_url: 'https://backend.ayanapower.com/api/',