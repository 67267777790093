import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import "./quality-safety.scss";
import quality_safety from "../../assets/img/quality_safety.png";
import Footer from "../../components/Footer/Footer";

const QualitySafety = () => {
  return (
    <div className="quality_safety">
      <Navbar />
      <section className="quality_breadcrumb">
        <div className="container-fluid">
          <div className="breadcrumb_text">
            <h4 className="breadcrumb_title">Quality, Environment, Health, and Safety</h4>
            <p className="breadcrumb_link">Home | Quality, Environment, Health, and Safety</p>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-4 text-center">
              <img src={quality_safety} className="img-fluid bg_blue" alt="" />
            </div>
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-12">
                  <div className="title text-center pt-4">
                    {/* <img src={title_img} className="img-fluid" alt="" /> */}
                    <h4>Quality, Environment, Health, and Safety</h4>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-11">
                  <div className="quality_safety_right">
                    <p>
                      Quality, health, safety, and environment are our
                      priorities while keeping this in mind AYANA RENEWABLE
                      POWER PRIVATE LIMITED (ARPPL) and its SPVs continuously
                      improve the QHSE performance and manage QHSE risk
                      associated with business activities, services, and
                      products. From time to time we try to improve our process
                      to run along with the changing business environment.{" "}
                      <br />
                      <br />
                      Our specialized team ensures that all our employees and
                      stakeholders are away from risk by preventing accidents,
                      incidents, and Occupational illnesses to people and the
                      environment and delivering customer satisfaction.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row text-center">
            <h5 className="blue_text">ARPPL and its SPVs will:</h5>
          </div>
          <div className="row">
            <ol className="arppl_list">
              <li>
                Provide management accountability and leadership in directing
                and delegating responsibilities for ensuring QHSE actions are
                performed.
              </li>
              <li>
                Plan QHSE management actions using a risk-based approach by
                determining risks and opportunities that need to be addressed
                related to business aspects, compliance obligations, other
                issues or needs, and expectations of interested parties, and
                determining effective risk mitigation and control measures.
              </li>
              <li>
                Clearly define QHSE roles, responsibilities, and
                accountabilities and support by ensuring adequate and competent
                resources are provided.
              </li>
              <li>
                Identify adverse hazards, sources, situations, or acts with the
                potential to cause harm to people or the environment or in terms
                of human injury, ill health, adverse physical and mental
                condition arising from and/or made worse by work or an activity
                related to ARPPL operations
              </li>
              <li>
                Use organizational knowledge based on lessons learned from
                success and failure, results of improvement processes, products,
                and services, external sources, knowledge from customer or
                external service providers, incident investigations, etc. to
                drive continuous improvement.
              </li>
              <li>
                Build, operate and maintain facilities in a manner that
                safeguards people, property, and the environment.
              </li>
              <li>
                Develop procedures, operational plans, and controls,
                establishing criteria for process, acceptance of product and
                services, and determining resources needed to achieve conformity
                of product and service requirements.
              </li>
              <li>
                Establish measurable QHSE performance indicators, objectives,
                and targets, controls on non-conforming outputs, and adherence
                to requirements of products and services.
              </li>
              <li>
                Creating, updating, and controlling documented information.
              </li>
              <li>
                Monitor, measure, analyze and evaluate applicable and measurable
                performance indicators, objectives, and targets.
              </li>
              <li>
                Review and evaluate operations to measure progress and ensure
                compliance with this policy via periodic system audits,
                identification of non-conformities, improvement opportunities,
                corrective and preventive action plans, and management review.
                Make subsequent changes to the QHSE management system where
                necessary.
              </li>
              <li>
                Provide a timely response to accidents or other emergency
                situations to mitigate the adverse impact on people, the
                environment, assets, and reputation. Adopt a philosophy of
                accident and incident prevention in the first instance.
              </li>
              <li>
                Review and evaluate operations to measure progress and ensure
                compliance with this policy via periodic system audits,
                identification of non-conformities, improvement opportunities,
                corrective and preventive action plans, and management review.
                Make subsequent changes to the QHSE management system where
                necessary.{" "}
              </li>
              <li>
                Ensure effective internal and external communication,
                participation and consultation on ARPPL operations. This
                includes maintaining a well-functioning community grievance
                mechanism
              </li>
              <li>
                Complying with applicable legislation, regulations, and other
                requirements applicable to business, and periodic evaluation of
                compliance obligations.
              </li>
              <li>
                Ensuring that changes or updates are carried out in a planned
                manner when the organization determines the need for change to
                the QHSE management system.{" "}
              </li>
              <li>Maintain a focus on customer needs and satisfaction.</li>
              <li>
                Educate, train, and communicate to employees and contractors, to
                be aware of, and understand QHSE risks associated with their job
                scope and take responsibility and be accountable for
                environmental protection, prevention of ill health, injuries
                accidents, and impacts on colleagues and the community when
                working on behalf of ARPPL.
              </li>
              <li>
                Periodically communicate the principles of this QHSE policy to
                interested and relevant stakeholders.
              </li>
            </ol>
          </div>
          <div className="row text-right">
            <h6>MD & CEO</h6>
            <h6>AYANA RENEWABLE POWER PRIVATE LIMITED</h6>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default QualitySafety;
