import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Carousel from "react-bootstrap/Carousel";

import "./careers.scss";
import { Link } from "react-router-dom";
import Pagination from "../../components/Pagination/Pagination";
import Spinner from "../../components/Spinner/Spinner";
import career_img1 from "../../assets/img/carreer_img1.png";
import carrer_img2 from "../../assets/img/carreer_img2.png";
import Navbar from "../../components/Navbar/Navbar";
import career_banner from "../../assets/img/careers-images/career-banner.jpg";
import banner1 from "../../assets/img/careers-images/banners/1.png";
import banner2 from "../../assets/img/careers-images/banners/2.png";
import banner3 from "../../assets/img/careers-images/banners/3.png";


import config from "../../config";

const BASE_URL = config.base_url;

const getJobsUrl            = `${BASE_URL}api/get-jobs`;
const getJobsAndLocationUrl = `${BASE_URL}api/get-location-and-jobs`;





const Careers = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [searchJob, setSearchJob] = useState("");
  const [searchLocation, setSearchLocation] = useState("");
  const [myJobs, setMyJobs] = useState([]);

  const searchHandler = () => {
    console.log(searchJob, "jobs");
    console.log(searchLocation, "location");
    if (searchLocation !== "" || searchJob !== "") {
      let newUrl = `${getJobsUrl}?job=${searchJob}&location=${searchLocation}`;
      console.log(newUrl);
      fetch(newUrl)
        .then((res) => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            setMyJobs(result.data);
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        );
    }
  };
  useEffect(() => {
  
    fetch(getJobsUrl)
      .then((res) => res.json())
      .then(
        (result) => {
          // setIsLoaded(true);
          // console.log(result.data[0].skills, "data");
          setMyJobs(result.data);
        },
        (error) => {
          // setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  useEffect(() => {
    console.log("my 2", myJobs);
  }, [myJobs]);

  useEffect(() => {
    fetch(getJobsAndLocationUrl)
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return (
      <div>
        <Navbar />
        <Spinner />
        <Footer />
      </div>
    );
  } else {

    return (
      <div style={{ overflow: "hidden" }} id="careers">
        <Navbar />
        <section className="pt-0 pb-0 position-relative career_slider_breadcrumb banner_image_overlay">
          {/* <img src={career_banner} alt="" className="img-fluid w-100" /> */}
          <Carousel fade>
            <Carousel.Item>
              <img src={banner1} className="d-block w-100" alt="..." />
            </Carousel.Item>
            <Carousel.Item>
              <img src={banner2} className="d-block w-100" alt="..." />
            </Carousel.Item>
            <Carousel.Item>
              <img src={banner3} className="d-block w-100" alt="..." />
            </Carousel.Item>
          </Carousel>
          <div className="breadcrumb_text">
            <h4 className="breadcrumb_title">Careers</h4>
            <p className="breadcrumb_link">
              <Link to="/"> Home</Link> | Careers
            </p>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-4 text-center">
                <img src={career_img1} alt="" className="img-fluid w-25" />
              </div>
              <div className="col-md-8 vision_mission_block ps-4">
                <div className="title  pt-4">
                  <h4>JOBS</h4>
                </div>
                <p>
                  Ayana Renewable Power is one of India's largest and
                  fastest-growing renewable energy companies. In just five
                  years, our presence has expanded across India. We are working
                  towards developing a 10 GWac portfolio by 2025 that can
                  provide clean power to millions of people.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="bg_grey" id={"careers"}>
          <div class="container text-center">
            <div class="row justify-content-center g-3">
              <div className="col-md-4">
                <select
                  onChange={(e) => setSearchJob(e.target.value)}
                  style={{ border: "none" }}
                  className="form-select shadow-none border-none"
                  aria-label="Default select example"
                >
                  <option option selected disabled value="">
                    Job Title
                  </option>
                  {items.jobs.map((data) => (
                    <>
                      <option value={data.id}>{data.name}</option>
                    </>
                  ))}
                </select>
              </div>
              <div class="col-md-4">
                <div className=" job_drop_down">
                  <select
                    onChange={(e) => setSearchLocation(e.target.value)}
                    style={{ border: "none" }}
                    className="form-select shadow-none"
                    aria-label="Default select example3"
                  >
                    <option option selected disabled value="">
                      Location
                    </option>
                    {items.locations.map((data) => (
                      <>
                        <option value={data.id}>{data.name}</option>
                      </>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-2 search_btn_container">
                <button onClick={searchHandler} className="btn btn_blue">
                  Search Job
                </button>
              </div>
            </div>
          </div>
        </section>
          
        

        <section className="bg_grey pos_relative">
        {myJobs.length > 0 ? (
            <>
              <Pagination jobs={myJobs} itemsPerPage={4} />
              <img src={carrer_img2} alt="" className="img-fluid career_img2" />
            </>
          ) : (
            <p className="text-center py-5">no jobs found currently at the given location</p>
          )}
        </section>
        <Footer />
      </div>
    );
  }
};

export default Careers;

// news dyanmic
// projects dyanamic
// life at ayana dyanamic
