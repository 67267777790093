const data = [
  {
    title: "March 2017",
    cardDetailedText: `March 01: British International Investment incorporates “Ayana Renewable Power”`,
  },
  {
    title: "June 2018",
    cardDetailedText: "June 05: Wins the first 250 MW solar PV project",
  },
  {
    title: "November 2018 ",
    cardDetailedText:
      "November 01: Signs a PPA with NTPC for a 250 MW solar PV project",
  },
  {
    title: "February 2019",
    cardDetailedText:
      "February 02: Completes primary fundraising of USD 330 Mln",
  },
  {
    title: "November 2019",
    cardDetailedText:
      "November 26: Signs a PPA with SECI for a 300 MW solar PV project",
  },
  {
    title: "July 2020",
    cardDetailedText:
      "July 15: Ayana crossed 1GW Portfolio mark with 300 MW SECI ISTS Solar Project at Rajasthan, taking the total portfolio of Ayana to 1.1 GW",
  },
  {
    title: "July 2020",
    cardDetailedText:
      "July 24: Ayana acquired two Solar PV Assets of First Solar, 20 MW each",
  },
  {
    title: "February 2022",
    cardDetailedText:
      "February 15: Partners with Greenko for standalone storage capacity  to develop firm and dispatchable RE solutions including Round-the-Clock power supply (RTC) of up to 1 GW",
  },
  {
    title: "April 2022",
    cardDetailedText:
      "April 26: Partners with Greenstat Hydrogen India for a green hydrogen pilot project",
  },
  {
    title: "July 2022",
    cardDetailedText:
      "July 07: The company expands its corporate office in Bengaluru, Karnataka",
  },
  {
    title: "October 2022",
    cardDetailedText:
      " October 21: Ayana receives an LOI for the development of a 140 MW wind project in Gujarat",
  },
  {
    title: "November 2022",
    cardDetailedText:
      "November 04: Signs MoU with the Government of Karnataka to develop renewable energy projects with an INR 15,000 crore investment",
  },
  {
    title: "April 2023",
    cardDetailedText:
      "April 26: Won a tender of 300MW of Round-the-Clock(RTC) Renewable Energy by REMCL a Joint Venture company of Ministry of Railways and RITES Ltd.",
  },
];

export default data;
