import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAllBlogs } from "../../../network/users";
import { deleteBlogByID } from "../../../network/admin";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

const BlogsTable = () => {
  const [tableData, setTableData] = useState([]);
  const [superTableData, setSuperTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState("");
  const navigate = useNavigate();

  const getAllBlogsFromAPI = async () => {
    try {
      const resp = await getAllBlogs();
      console.log(resp);
      if (resp) {
        console.log(resp);
        setTableData(resp);
        setSuperTableData(resp);
      }
    } catch (error) {}
  };

  const handleSearch = (e) => {
    const { value } = e.target;

    // Filter data based on the title property
    const filteredData = superTableData.filter((item) =>
      item.title.toLowerCase().includes(value.toLowerCase())
    );
    setTableData(filteredData);
  };
  const handleDelete = async () => {
    try {
      const resp = await deleteBlogByID(selectedBlog);

      if (resp) {
        toast.success(resp?.message);
        getAllBlogsFromAPI();
      } else {
        toast.error(resp?.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleEdit = (data) => {
    // Navigate to NextPage and pass propsToPass
    navigate(`/admin/blogs/edit/${data?._id}`);
  };

  function extractDate(dateTimeString) {
    const date = new Date(dateTimeString);

    // Extract date components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    // Formatted date string
    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
  }

  useEffect(() => {
    getAllBlogsFromAPI();
  }, []);
  return (
    <div>
      <div className="main-content">
        <div className="page-content">
          <div className="bread-c">
            <h6>Blog Post</h6>
          </div>
          <div className="card">
            <div className="card-header  d-flex justify-content-between align-items-center">
              <div className="card-title"></div>
              <div className="card-toolbar col-6">
                <div className="card-toolbar flex-row-fluid row justify-content-end gap-1 me-3">
                  {/* <div className="btn btn-primary col-3">
                    <i className="fas fa-sort"></i> Reorder
                  </div> */}
                  <Link
                    to={"/admin/blogs/create"}
                    className="btn btn-primary col-3"
                  >
                    <i className="fas fa-plus"></i> Create
                  </Link>
                </div>
              </div>
            </div>
            <div className="card-body py-4">
              <table
                className="table table-striped table-row-bordered gy-5 gs-7 border rounded w-100 margin-t"
                id="datatable"
              >
                <thead>
                  <tr className="fw-bolder fs-6 text-gray-800 px-7">
                    <th>Sl</th>
                    <th>Title</th>
                    <th width="150px">Description</th>
                    <th width="150px">Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="cb-row">
                  {tableData?.map((item, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item?.title}</td>
                        <td>{item?.description}</td>
                        <td>{extractDate(item?.createdAt)}</td>
                        <td>
                          <div className="d-flex gap-3 align-items-center">
                            <i
                              onClick={() => {
                                setShowModal(true);
                                setSelectedBlog(item?.id);
                              }}
                              className="fas fa-trash text-danger"
                            ></i>
                            <i
                              onClick={() => handleEdit(item)}
                              className="fas fa-pen"
                            ></i>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          {showModal && (
            <div
              id="default-modal"
              className="modal show d-block"
              style={{ zIndex: 1050 }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h3 className="modal-title text-3xl font-semibold text-gray-900">
                      Are you sure you want to delete?
                    </h3>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={() => setShowModal(false)}
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="modal-footer">
                      <button
                        type="button"
                        onClick={() => {
                          handleDelete();
                          setShowModal(false);
                        }}
                        className="btn btn-danger btn-lg me-3"
                      >
                        Delete
                      </button>
                      <button
                        type="button"
                        onClick={() => setShowModal(false)}
                        className="btn btn-outline-secondary btn-lg"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BlogsTable;
