import React from "react";
import { Link } from "react-router-dom";

import "./contact.scss";
import Footer from "../../components/Footer/Footer";

import ContactForm from "../../components/ContactForm";
import Navbar from "../../components/Navbar/Navbar";
import contact_banner from "../../assets/img/contact-banner.png";

const Contact = () => {
  return (
    <div className="contact" style={{ overflow: "hidden" }}>
      <Navbar />
      <section
        className="pt-0 pb-0 position-relative banner_image_overlay"
        id={"contact"}
      >
        <img src={contact_banner} alt="" className="img-fluid w-100" />
        <div className="breadcrumb_text">
          <h4 className="breadcrumb_title">CONTACT US</h4>
          <p className="breadcrumb_link">
            <Link to="/"> Home</Link> | CONTACT US
          </p>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row introductionContainer">
            <div className="col-md-12">
              <div>
                <div className="row introductionContainer">
                  <div className="col-md-3">
                    <div
                      className="title pt-4 mb-0"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <h4></h4>
                    </div>
                  </div>
                </div>
                <p>
                  At Ayana Renewable Power, collaboration is at the heart of
                  everything we do. By working together and sharing ideas, we
                  always find innovative solutions to the challenges facing the
                  renewable energy industry. Here’s how you can collaborate with
                  us.
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <ContactForm />
            <div className=" col-md-6">
              <div className="mapContainer">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15549.478506465983!2d77.5563055!3d13.0121179!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x282285f005322145!2sAyana%20Renewable%20Power%20Private%20Limited!5e0!3m2!1sen!2sin!4v1671520623132!5m2!1sen!2sin"
                  width="100%"
                  height="520px"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                  title="Ayana Power"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="contact_bg mb-4">
        <div className="row">
          <div className="col-md-12">
            <div className="contact_address text-center p-1">
              <center>
                <div className="title">
                  <h4>LOCATION</h4>
                </div>
              </center>
              <a
                href="https://goo.gl/maps/J4upjjFus7XYBkYi9"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-sharp fa-solid fa-location-dot mt-3"></i>
              </a>

              <h5 className="pt-4">Corporate Head Office</h5>
              <p className="locationText">
                S 2904, 29th floor, World Trade Center,
                <br /> Brigade Gateway Campus, #26/1, Dr. Rajkumar Road,
                Rajajinagar, Bengaluru – 560 055, Karnataka
              </p>
            </div>
          </div>
        </div>
      </section> */}
      <section className="contact_bg mb-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-7 col-lg-5 ps-0">
              <div className="contact_address text-center p-1">
                <center>
                  <div className="title">
                    <h4>ENQUIRIES</h4>
                  </div>
                </center>
                <a
                  href="https://goo.gl/maps/J4upjjFus7XYBkYi9"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa-sharp fa-solid fa-envelope  mt-3"></i>
                </a>

                <h5 className="pt-4">Emails</h5>
                <ul className="contact_mails text-left ps-2 ps-md-2 ps-lg-5">
                  <li>
                    <span className="fw-bold">General Enquiries:</span>
                    <a
                      href="mailto:hr@ayanapower.com"
                      className="text-black ps-2"
                    >
                      contact@ayanapower.com
                    </a>
                  </li>
                  <li>
                    <span className="fw-bold">Human Resource:</span>
                    <a
                      href="mailto:hr@ayanapower.com"
                      className="text-black ps-2"
                    >
                      {" "}
                      hr@ayanapower.com
                    </a>
                  </li>
                  <li>
                    <span className="fw-bold">Business Development:</span>
                    <a
                      href="mailto:hr@ayanapower.com"
                      className="text-black ps-2"
                    >
                      bd@ayanapower.com
                    </a>
                  </li>
                  <li>
                    <span className="fw-bold">Grievance:</span>
                    <a
                      href="mailto:hr@ayanapower.com"
                      className="text-black ps-2"
                    >
                      grievance@ayanapower.com
                    </a>
                  </li>
                  <li>
                    <span className="fw-bold">Media:</span>
                    <a
                      href="mailto:hr@ayanapower.com"
                      className="text-black ps-2"
                    >
                      communication@ayanapower.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-5 col-lg-5 vision_mission_block ps-4">
              <div className="contact_address text-center p-1">
                <center>
                  <div className="title">
                    <h4>LOCATION</h4>
                  </div>
                </center>
                <a
                  href="https://goo.gl/maps/J4upjjFus7XYBkYi9"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa-sharp fa-solid fa-location-dot mt-3"></i>
                </a>

                <h5 className="pt-4">Registered and Corporate Head office</h5>
                <p className="locationText">
                Ayana Renewable Power Private Limited<br />
                  CIN U40106KA2017FTC101000 <br />
                  S 2904, 29th floor, World Trade Center, Brigade Gateway Campus, #26/1, Dr. Rajkumar Road, Rajajinagar, Bengaluru – 560 055, Karnataka
                </p>
                {/* <p className="locationText">
                  S 2904, 29th floor, World Trade Center,
                  <br /> Brigade Gateway Campus, #26/1, Dr. Rajkumar Road,
                  Rajajinagar, Bengaluru – 560 055, Karnataka
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Contact;
