import React from "react";
import "./ayana-life.scss";
import Carousel from "react-bootstrap/Carousel";

import sajida from "../../assets/img/team/sajida.png";
import about_corner from "../../assets/img/about_corner.png";
import titleImg from "../../assets/img/title_img.png";
import titleImgWhite from "../../assets/img/title_img_white.svg";
import Footer from "../../components/Footer/Footer";
import SliderTeam from "../../components/SliderTeam/SliderTeam";
import AyanaLifeSlider from "../../components/AyanaLifeSlider/AyanaLifeSlider";
import { Link } from "react-router-dom";
import FunatWorkSlider from "../../components/FunatWorkSlider/FunatWorkSlider";
import RewardRecognitionSlider from "../../components/RewardRecognitionSlider/RewardRecognitionSlider";
import Infographic from "../../components/Infographic/Infographic";
import Navbar from "../../components/Navbar/Navbar";
// import ayana_life_banner from "../../assets/img/lifeayana-banner.png";
import banner1 from "../../assets/img/ayana_life/banners/1.png";
import banner2 from "../../assets/img/ayana_life/banners/2.png";
import banner3 from "../../assets/img/ayana_life/banners/3.png";

export const AyanaLife = () => {
  return (
    <div className="overflow-hidden">
      <Navbar />
      <section
        className="pt-0 pb-0 position-relative ayana_life_slider_breadcrumb banner_image_overlay"
        id={"ayana-life"}
      >
        {/* <img src={ayana_life_banner} alt="" className="img-fluid w-100" /> */}
        <Carousel fade>
          <Carousel.Item>
            <img src={banner3} className="d-block w-100" alt="..." />
          </Carousel.Item>
          <Carousel.Item>
            <img src={banner2} className="d-block w-100" alt="..." />
          </Carousel.Item>
          <Carousel.Item>
            <img src={banner1} className="d-block w-100" alt="..." />
          </Carousel.Item>
        </Carousel>
        {/* <div
          id="carouselExampleCaptions"
          className="carousel slide carousel-fade"
          data-bs-ride="carousel"
          data-bs-pause="false"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active" data-bs-interval="2000">
              <img src={banner3} className="d-block w-100" alt="..." />
            </div>
            <div className="carousel-item" data-bs-interval="2000">
              <img src={banner2} className="d-block w-100" alt="..." />
            </div>
            <div className="carousel-item" data-bs-interval="2000">
              <img src={banner1} className="d-block w-100" alt="..." />
            </div>
          </div>
          
        </div> */}
        <div className="breadcrumb_text">
          <h4 className="breadcrumb_title">Life @ Ayana</h4>
          <p className="breadcrumb_link">
            <Link to="/"> Home</Link> | Life @ Ayana
          </p>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4 text-center">
              <img src={about_corner} alt="" className="img-fluid" />
            </div>
            <div className="col-md-8 vision_mission_block ps-4">
              <p>
                At Ayana Renewable Power, our work culture isn't just about
                getting the job done, it's also about how we do it. We celebrate
                each other's wins and collaborate to solve challenges. Our
                integrity guides us in making ethical decisions, while
                innovation drives us to continuously improve and find new
                solutions. We strive to create a positive impact by imbibing
                fun, collaboration, celebration, and innovation in our work
                culture.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="pos_relative">
        <div className="container-fluid">
          <div className="row align-items-end">
            <div className="col-md-10 m-2">
              <div className="first_sec_life_right ">
                <p className="text-center m-4">
                  At Ayana Renewable Power, our work culture isn't just about
                  getting the job done, it's also about how we do it. We
                  celebrate each other's wins and collaborate to solve
                  challenges. Our integrity guides us in making ethical
                  decisions, while innovation drives us to constantly improve
                  and find new solutions. We strive to create a positive impact
                  by imbibing fun, collaboration, celebration, and innovation in
                  our work culture
                </p>
              </div>
              <img
                className="ayana_life_corner_img"
                src={about_corner}
                alt=""
              />
            </div>
          </div>
        </div>
      </section> */}
      <section className="bg_grey">
        <div className="container">
          <div className="">
            <div className="row">
              <div className="col-md-4 text-center">
                <img src={sajida} alt="sajida" className="img-fluid" />
              </div>
              <div className="col-md-8">
                <div className="ceo_right_bottom">
                  <div className="title">
                    <h3>Sajida M</h3>
                  </div>
                  <p className="ceo_name">Assistant Vice President & Head HR</p>
                  <blockquote className="">
                    At Ayana, our work culture emphasises teamwork and equal
                    collaboration among all team members. We encourage open
                    communication and the sharing of ideas, which help to create
                    a positive and productive environment. By fostering a
                    culture of mutual respect, we are able to work effectively
                    towards our shared goals.
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Infographic />
      <section className="bg_grey pb-0">
        <div className="title text-center  ">
          <center>
            <h4>Employee Stories</h4>
          </center>
          {/* <p className="careerText">Our employees have a lot to say</p> */}
        </div>

        <SliderTeam />
      </section>

      <section className="life_at_ayana">
        <div className="container">
          <div className="text-center">
            <img src={titleImg} alt="" />
            <h4 className="lifeProjectHeading">Life @ Ayana</h4>
          </div>
          <div className="row pt-4 align-items-center">
            <div className="col-md-12 col-lg-8 order-2 order-lg-1">
              <div
                id="carouselExampleInterval"
                className="carousel slide"
                // data-bs-ride="carousel"
              >
                <div className="carousel-inner">
                  <div
                    className="carousel-item px-1 px-md-2 px-lg-5 active"
                    data-bs-interval="3000"
                  >
                    <div className="scrollable_contents px-3 px-md-4 px-lg-0">
                      <h5>The culture of innovation</h5>
                      <p className="pe-2">
                        At Ayana Renewable Power, innovation is ingrained in our
                        DNA. We foster a culture of continuous learning,
                        exploration, and collaboration, encouraging our team to
                        think outside the box and push the boundaries of what's
                        possible in the renewable energy industry. Our
                        relentless pursuit of innovative solutions drives us to
                        stay ahead of the curve, embrace emerging technologies,
                        and develop cutting-edge approaches to address the
                        challenges of grid stability, efficient scheduling, and
                        sustainable power generation. With innovation at our
                        core, we are committed to driving the energy transition
                        and shaping a brighter future for our planet.
                      </p>
                    </div>
                  </div>
                  <div
                    className="carousel-item px-1 px-md-2 px-lg-5"
                    data-bs-interval="2000"
                  >
                    <div className="scrollable_contents px-3 px-md-4 px-lg-0">
                      <h5>Collaboration at Ayana</h5>
                      <p className="pe-2">
                        At Ayana Renewable Power, collaboration is the
                        cornerstone of our success. We believe that by working
                        together, we can achieve remarkable outcomes and drive
                        meaningful change in the renewable energy sector. Our
                        culture of collaboration encourages open communication,
                        knowledge sharing, and collective problem-solving. We
                        foster an environment where every team member's voice is
                        valued, creating a space for diverse perspectives and
                        ideas to flourish. Through cross- functional
                        collaboration, we leverage the expertise and strengths
                        of everyone, leading to innovative solutions, enhanced
                        productivity, and continuous improvement. By embracing a
                        collaborative culture, we foster a sense of ownership,
                        trust, and mutual respect among our team members,
                        enabling us to overcome challenges and achieve our
                        shared goals. Together, we are building a sustainable
                        future and making a positive impact on the world.
                      </p>
                    </div>
                  </div>
                  <div
                    className="carousel-item px-1 px-md-2 px-lg-5"
                    data-bs-interval="2000"
                  >
                    <div className="scrollable_contents px-3 px-md-4 px-lg-0">
                      <h5>Team which celebrates together stays together</h5>
                      <p className="pe-2">
                        At Ayana Renewable Power, we firmly believe that the
                        team which celebrates together stays together. We
                        understand the importance of creating a vibrant and
                        inclusive work environment where our team members feel
                        valued, appreciated, and connected. Life@Ayana is not
                        just about work; it's about fostering a sense of
                        camaraderie, building lasting relationships, and
                        celebrating our collective achievements. By nurturing a
                        culture of celebration and togetherness, we cultivate a
                        positive and supportive work environment. It encourages
                        collaboration, boosts morale, and promotes a sense of
                        belonging among our team members. We believe that these
                        shared experiences play a crucial role in building
                        strong, motivated, and dedicated teams who are inspired
                        to achieve greatness together.
                      </p>
                    </div>
                  </div>
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleInterval"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleInterval"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
              {/* <p>
                We believe that a happy workforce is productive. We focus on
                building environments where our people can share their joys and
                motivate each other to work as part of an important team for the
                company's success. It helps us create positive momentum within
                the teams themselves by increasing productivity among all
                members involved. We focus on creating meaningful memories from
                events such as birthdays celebrations, annual days or special
                occasions celebrated throughout different times during Ayana’s
                year-long calendar.
              </p> */}
            </div>
            <div className="col-md-12 col-lg-4 pb-2 pt-0 order-1 order-lg-2">
              <div>
                <FunatWorkSlider />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg_blue">
        <div className="container">
          <div className="text-center">
            <img src={titleImgWhite} alt="" />
            <h4 className="lifeProjectHeading" style={{ color: "white" }}>
              Rewards & Recognition
            </h4>
          </div>
          <div className="row pt-4 pb-4">
            <div className="rewardsSlider">
              <div>
                <div style={{ marginTop: "-60px" }}>
                  <center>
                    <RewardRecognitionSlider />
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="life_at_project_site">
        <div className="container">
          <div className="text-center">
            <img src={titleImg} alt="" />
            <h4 className="lifeProjectHeading">Life @ Project site</h4>
          </div>
          <div className="row pt-4  align-items-center">
            <div className="col-md-12 col-lg-8 order-2 order-lg-1">
              <div
                id="carouselExampleInterval1"
                className="carousel slide"
                // data-bs-ride="carousel"
              >
                <div className="carousel-inner">
                  <div
                    className="carousel-item px-1 px-md-2 px-lg-5 active"
                    data-bs-interval="3000"
                  >
                    <div className="scrollable_contents px-3 px-md-4 px-lg-0">
                      <h5>
                        Life at the Project Site: A Journey of Challenges and
                        Triumphs
                      </h5>
                      <p className="mt-3 pe-2">
                        As a Site Manager at Ayana Renewable Power, overseeing
                        the installation of wind turbines, every day is an
                        adventure filled with immense challenges and countless
                        tasks to manage. Being at the forefront of such a
                        critical project requires dedication, expertise, and the
                        ability to navigate through unforeseen obstacles that
                        arise along the way.
                      </p>
                      <p className="mt-3 pe-2">
                        One of the most significant challenges I face is the
                        complex logistics involved in wind turbine installation.
                        Coordinating the transportation of massive turbine
                        components, such as blades, nacelles, and towers, from
                        various suppliers to the project site demands meticulous
                        planning and seamless execution. Ensuring timely
                        delivery while adhering to strict project schedules and
                        optimizing the use of resources is a constant juggling
                        act.
                      </p>
                      <p className="mt-3 pe-2">
                        Health and safety is another paramount concern at the
                        project site. With a firm commitment to the well-being
                        of our workforce, I am responsible for implementing
                        stringent safety protocols, conducting regular training
                        sessions, and maintaining a culture of safety awareness.
                        Ensuring that every team member follows safety
                        guidelines and practices is non-negotiable. Mitigating
                        potential risks and creating a safe working environment
                        is an ongoing priority.
                      </p>
                      <p className="mt-3 pe-2">
                        Amidst these challenges, witnessing the successful
                        installation of wind turbines and the project coming to
                        life is immensely rewarding. The collaborative spirit
                        among the team members, their unwavering dedication, and
                        the resilience displayed in the face of adversity are
                        the driving forces behind our triumphs. The sense of
                        accomplishment when the turbines start generating clean,
                        renewable energy is indescribable.
                      </p>
                      <p className="mt-3 pe-2">
                        Life at the project site is demanding, but it is also
                        filled with moments of pride and fulfillment. Overcoming
                        challenges, fostering a culture of teamwork, and
                        ensuring the safety and well-being of our workforce are
                        at the heart of my role as a Site Manager. Together, we
                        embrace the unpredictable nature of wind turbine
                        installation, and through our collective efforts, we
                        contribute to a greener and more sustainable future.
                      </p>
                      <p className="mt-3 px-3 fw-bold">
                        Dheenathayalan Loganathan Site Manager- Gadag, Karnataka
                      </p>
                    </div>
                  </div>
                  <div
                    className="carousel-item px-1 px-md-2 px-lg-5"
                    data-bs-interval="2000"
                  >
                    <div className="scrollable_contents px-3 px-md-4 px-lg-0">
                      <h5>
                        Life at the Project Site: Safeguarding Our Most Valuable
                        Asset
                      </h5>
                      <p className="mt-3 pe-2">
                        People As a Health and Safety Manager at Ayana Renewable
                        Power, my role revolves around one central mission:
                        ensuring the well-being and safety of every individual
                        working at the project site. Each day presents unique
                        challenges, but with unwavering dedication and a firm
                        commitment to safety, we strive to create an environment
                        where everyone can work without compromising their
                        well-being.
                      </p>
                      <p className="mt-3 pe-2">
                        Working in Bikaner, Rajasthan, presents its own set of
                        demanding conditions. The scorching heat tests our
                        endurance, making it crucial to implement measures that
                        protect our workforce from heat- related risks.
                        Educating and training our team on heat stress
                        prevention, providing personal protective equipment, and
                        establishing shaded rest areas are just a few of the
                        steps we take to ensure the health and safety of our
                        employees.
                      </p>
                      <p className="mt-3 pe-2">
                        Ensuring adherence to safety standards is
                        non-negotiable. The meticulous implementation of safety
                        protocols, procedures, and guidelines is critical to
                        mitigating risks and preventing accidents. As a Health
                        and Safety Manager, I work closely with the entire
                        project team, emphasizing the importance of following
                        these standards at all times, even if it means taking
                        extra time or exerting additional effort. Our commitment
                        to safety surpasses any timeline or deadline, and the
                        well-being of our workforce remains our top priority.
                      </p>
                      <p className="mt-3 pe-2">
                        At times, enforcing safety measures can be challenging,
                        particularly when dealing with tight schedules and
                        project demands. However, I firmly believe that by being
                        tough when it comes to safety, we foster a culture that
                        values the lives and well-being of our team members
                        above all else. We continuously communicate the
                        importance of safety, conduct regular training sessions,
                        and encourage open dialogue to address concerns and
                        identify areas for improvement.
                      </p>
                      <p className="mt-3 pe-2">
                        Life at the project site as a Health and Safety Manager
                        is both demanding and fulfilling. We navigate through
                        challenges, we adapt to changing circumstances, and we
                        continuously strive to improve our safety practices.
                        Together, we create a project site where our most
                        valuable asset—our people—are protected, ensuring that
                        they can perform their duties with peace of mind and
                        return home safely to their loved ones.
                      </p>

                      <p className="mt-3 pe-2 fw-bold">
                        Rohit Bajpai QHSE Team, Bikaner, Rajasthan
                      </p>
                    </div>
                  </div>
                  {/* <div className="carousel-item px-1 px-md-2 px-lg-5" data-bs-interval="2000">
                    <p className="scrollable_contents px-3 px-md-4 px-lg-0">
                      Our people are the heart and soul of our success, and we
                      work diligently to cultivate a positive atmosphere where
                      everyone feels valued and supported. We prioritise
                      training and collaboration, building strong teams that
                      strive for excellence in everything they do. Asset
                      management is also a top priority, as we take pride in our
                      facilities and equipment. And after a job well done, we
                      love to come together for some well-deserved celebration
                      through festivities and events such as sports day. We work
                      hard every day to lay the foundation for a brighter
                      tomorrow. But most importantly, we have fun doing it.
                    </p>
                  </div> */}
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleInterval1"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleInterval1"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
              {/* <p>
                Our people are the heart and soul of our success, and we work
                diligently to cultivate a positive atmosphere where everyone
                feels valued and supported. We prioritise training and
                collaboration, building strong teams that strive for excellence
                in everything they do. Asset management is also a top priority,
                as we take pride in our facilities and equipment. And after a
                job well done, we love to come together for some well-deserved
                celebration through festivities and events such as sports day.
                We work hard every day to lay the foundation for a brighter
                tomorrow. But most importantly, we have fun doing it.
              </p> */}
            </div>
            <div className="col-md-12 col-lg-4 pb-2 pt-0 order-1 order-lg-2">
              <div>
                <AyanaLifeSlider />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default AyanaLife;
