import React from "react";
import { Link } from "react-router-dom";

import Navbar from "../../components/Navbar/Navbar";
import privacypolicy from "../../assets/img/Privacy-Policy/privacy policy.png";
import icon1 from "../../assets/img/TermsConditons/1.png";
import icon2 from "../../assets/img/TermsConditons/2.png";
import icon3 from "../../assets/img/TermsConditons/3.png";
import icon4 from "../../assets/img/TermsConditons/4.png";
import arrow from "../../assets/img/TermsConditons/arrow.svg";
import "./privacy-policy.scss";
import Footer from "../../components/Footer/Footer";

const PrivacyPolicy = () => {
  return (
    <div className="privacypolicy">
      <Navbar />
      <section
        className="pt-0 pb-0 position-relative banner_image_overlay"
        id={"privacypolicy"}
      >
        <img src={privacypolicy} alt="" className="img-fluid w-100" />
        <div className="breadcrumb_text">
          <h4 className="breadcrumb_title">Privacy Policy</h4>
          <p className="breadcrumb_link">
            <Link to="/"> Home</Link> | Privacy Policy
          </p>
        </div>
      </section>

      <section>
        <div className="container termsHeading">
          <h2 className="mb-3">Privacy Policy</h2>
        </div>
        <div className="col-md-12 px-4">
          <div className="container row mt-2 g-3 py-4 justify-content-center">
            <div className=" ">
              <div className="d-flex align-items-start gap-3 listHeading">
                <img src={icon1} alt="" />
                <div>
                  <h2 className="mb-0 ">Protecting The Privacy</h2>
                  <p className="">
                    All information submitted by the Users to the website of
                    Ayana shall be safeguarded and protected. Ayana is committed
                    to protecting the privacy, confidentiality and security of
                    the personal information that it collects, uses, retains and
                    discloses in the course of conducting business. This
                    information protection policy will protect the data provided
                    by you and will be used for purposes of processing your
                    application, the assessment and processing of claims and any
                    other administration relevant to any policy issued by Ayana.
                    By using this Website, you agree to the terms of this
                    privacy statement. 
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 ">
              <div className="d-flex align-items-start gap-3 listHeading">
                <img src={icon2} alt="" />
                <div>
                  <h2 className="mb-0">Security Measures</h2>
                  <div className="d-flex align-items-start gap-3  mt-2">
                    <p>
                      We are committed to prevent the loss, misuse, unauthorized
                      access, disclosure, alteration, and destruction of your
                      information. Our commitment to security extends to the
                      contracts and agreements that we sign with external
                      suppliers and service providers. We will only retain your
                      information for as long as required for the purposes for
                      which it was collected and/or any legal or regulatory
                      requirements.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 ">
              <div className="d-flex align-items-start gap-3 listHeading">
                <img src={icon3} alt="" />
                <div>
                  <h2 className="mb-0">Use Of The Information Collected</h2>
                  <div className="d-flex align-items-start gap-3  mt-2">
                    <p>
                      The information collected by us includes your contact
                      information such as your name, address, and email address
                      and other details as given in the registration page of our
                      website.By using this Website, you authorize us to contact
                      you via email or phone on your contact details provided
                      above and offer you information and services for which you
                      have opted for.
                    </p>
                  </div>
                  <div className="d-flex align-items-start gap-3  mt-2">
                    <p>
                      We may collect, use and disclose your personal information
                      to:
                    </p>
                  </div>

                  <div className="d-flex align-items-start gap-3  mt-2">
                    <img className="mt-2" src={arrow} alt="" />
                    <p>Identify you</p>
                  </div>

                  <div className="d-flex align-items-start gap-3  mt-2">
                    <img className="mt-2" src={arrow} alt="" />
                    <p>
                      Confirm your application information and assess your
                      eligibility for the area that concerns you
                    </p>
                  </div>

                  <div className="d-flex align-items-start gap-3  mt-2">
                    <img className="mt-2" src={arrow} alt="" />
                    <p>Understand your needs</p>
                  </div>
                  <div className="d-flex align-items-start gap-3  mt-2">
                    <img className="mt-2" src={arrow} alt="" />
                    <p>Provide information that may be of interest to you</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 ">
              <div className="d-flex align-items-start gap-3 listHeading">
                <img className="" src={icon4} alt="" />
                <div>
                  <h2 className="mb-0">
                    Third Party Postings and Hyperlinks to Third Party Websites
                  </h2>
                  <div className="d-flex align-items-start gap-3  mt-2">
                    <p>
                      This website may include links to external websites
                      (“External Sites”). While Ayana website is subject to this
                      privacy statement, we are not responsible for the privacy
                      practices of External Sites. Any information you provide
                      to these third parties will be subject to their respective
                      privacy policies. We therefore recommend that you read the
                      privacy statement for each External Site you visit. Ayana
                      is not responsible for the owners or operators of these
                      External Sites or for any goods or services they supply
                      and does not give any warranty or representation or accept
                      any liability in relation to any of these (including
                      without limitation any liability arising out of any claim
                      that the content of any External Site to which this
                      website includes a link infringes the intellectual
                      property rights of any third party).
                    </p>
                  </div>
                  <div className="d-flex align-items-start gap-3  mt-2">
                    <p>
                      Ayana shall not be held responsible for any errors or
                      omissions in any content of any hyperlinked External Site
                      or any hyperlink contained in a hyperlinked External Site,
                      nor for the privacy and security practices employed by
                      these External Sites. Under no circumstances shall Ayana
                      be liable for any loss or damage of any kind that you may
                      incur as a result of the use of any content posted or
                      displayed on this website or arising from access to
                      External Sites. Use of the any hyperlinks and access to
                      External Sites are entirely at your own risk. You
                      acknowledge that Ayana has no control over and excludes
                      all liability for any material on the Internet which can
                      be accessed by using this website.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container termsHeading">
          <h2 className="mb-3">Contact Us</h2>
        </div>
        <div className="mt-2">
          <p className="text-center">
            If you have any comments or concerns regarding this information
            protection policy, please contact:
          </p>
          <div className="text-center">
            <a className="emailLink" href="mailto:communication@ayanapower.com">
              communication@ayanapower.com
            </a>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
